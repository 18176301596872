import { WEB_SERVER_ENDPOINT } from '@/constants'
import { ToastContext } from '@/contexts/ToastContext'
import { RootState } from '@/store/store'
import {
  FileResource,
  ResourceVisibility,
  ResponseSignedUrlUpload,
  ResponseSignedUrlsUpload,
  UploadableFile,
} from '@/types/types'
import { handleError } from '@/utils/handleError'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

interface UseFileUploadProps {
  onSubmit: (
    uploadedFiles: UploadableFile[],
    visibility: ResourceVisibility
  ) => void
  preferredVisibility: ResourceVisibility
  isEnabled?: boolean
}
export const useFileUpload = ({
  onSubmit,
  preferredVisibility,
  isEnabled = false,
}: UseFileUploadProps) => {
  const { showToast } = useContext(ToastContext)
  const { files: resources } = useSelector((state: RootState) => state.document)

  const resourceMap = useMemo(() => {
    return new Map(resources.map((r) => [r.document_id, r]))
  }, [resources])

  const getSignedUrls = async (
    resources: FileResource[]
  ): Promise<ResponseSignedUrlsUpload> => {
    const res = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/document/upload-signed-urls`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          resources: resources,
        }),
        credentials: 'include',
      }
    )
    if (!res.ok) {
      console.error(`[desia-web-app] failed to get signed urls`, { resources })
      throw new Error("Couldn't upload files")
    }
    return await res.json()
  }

  const uploadSignedFile = async (
    file: File,
    signedUpload: ResponseSignedUrlUpload
  ): Promise<string> => {
    const res = await fetch(signedUpload.signed_url, {
      method: 'put',
      headers: {
        ...signedUpload.headers,
        'Content-Type': 'application/octet-stream',
      },
      body: file,
    })
    if (!res.ok) {
      console.error(`[desia-web-app] failed to uploadSignedFile`, {
        file,
        signedUpload,
      })
      throw new Error('failed to upload file')
    }
    return await res.text()
  }

  const transformToFileResource = (filesToTransform: UploadableFile[]) => {
    return filesToTransform.map((file) => {
      return {
        resource_name: file.file.name,
        resource_visibility: preferredVisibility,
        resource_overwrite: true,
        resource_shared_with_user_ids: [],
      } as FileResource
    })
  }

  const handler = async (uploadableFiles: UploadableFile[]) => {
    if (!uploadableFiles) return

    let updatedFiles: UploadableFile[]

    try {
      const uploadableFileResources = transformToFileResource(uploadableFiles)

      const signedFiles = await getSignedUrls(uploadableFileResources)
      console.debug("[desia-web-app] signed files", { signedFiles })

      updatedFiles = uploadableFiles.map((file) => {
        const signedFileMetadata =
          signedFiles.signed_urls.find((s) => s.file_name === file.file.name) ||
          null

        return { ...file, signedUpload: signedFileMetadata }
      })
      console.debug("[desia-web-app] updatedFiles", { updatedFiles })

    } catch (error) {
      handleError(error)
      showToast({
        variant: 'error',
        description: 'Failed to upload files',
        dismissable: true,
      })
      return
    }

    const uploadPromises: Promise<boolean>[] = updatedFiles.map(async (s) => {
      if (!s.signedUpload) {
        return true
      }
      try {
        const exists = Boolean(
          resourceMap.get(
            s.signedUpload?.headers['x-goog-meta-document_id'] as string
          )
        )

        if (!exists) {
          await uploadSignedFile(s.file, s.signedUpload)
        } else {
          s.skipUpload = exists
        }

        return true
      } catch (error) {
        handleError(error)
        console.error(`[desia-web-app] failed to upload file`, {
          uploadableFile: s,
        })
        return false
      }
    })
    console.debug("[desia-web-app] uploadPromises", { uploadPromises })


    const results = await Promise.all(uploadPromises)
    console.debug("[desia-web-app] uploadPromises results", { results })


    onSubmit(updatedFiles, preferredVisibility)

    if (!results.every((result) => result === true)) {
      showToast({
        variant: 'error',
        description: 'Failed to upload files',
        dismissable: true,
      })
    }
  }

  if (isEnabled) {
    return {
      getSignedUrls: getSignedUrls,
      uploadFiles: handler,
    }
  } else {
    return {
      getSignedUrls: () => {
        throw new Error('Upload file disabled')
      },
      uploadFiles: () => {
        throw new Error('Upload file disabled')
      },
    }
  }
}
