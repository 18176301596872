import { UPLOAD_ASK_ENABLED } from '@/constants'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Input } from '../ui/input'
import {
  DedupedSearchQueryItem,
  DocumentSelectionSource,
  DocumentSelectorDialogType,
  DossierDetail,
  FileStructure,
  Playbook,
  QueryStatus,
  SourceDocumentType,
  SourceSettings,
  SourceType,
} from '@/types/types'
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TypographyBody } from '../ui/Typography'
import { UserContext } from '@/contexts/UserContext'
import { ChevronDown, ChevronUp, Lightbulb, X } from 'lucide-react'
import {
  checkSourceDocumentType,
  groupSearchResultByCompany,
  isUploadFileEnabled,
  plural,
  toggleElement,
} from '@/utils/utils'
import { FileTable } from '../Integration/FileTable'
import { transformResponseDocuments } from '@/utils/transformResponseDocuments'
import { CustomAlert } from '../CustomAlert'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { listDocuments } from '../Document/documentThunk'
import ArrowRight from '@/assets/ArrowRight'
import { validFocusedAnalysisFilesTypes } from '@/constants'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { fetchCompanyResults } from '../Resources/searchThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { FileAnalysisCard } from '../FileAnalysisCard'
import { TableHeaderStyle, TableHeadStyle } from '../ui/table'
import { SearchFileTable } from '../SearchFileTable'
import texture from '../../assets/bg-texture.png'
import FileDropzone, { FILE_DROPZONE_INFO_MESSAGE } from './FileDropzone'
import Divider from '../ui/divider'
import { actions as impromptuUploadActions } from '../Document/impromptuUploadSlice'
import { PlaybookOptionRadio } from '../Playbook/PlaybookOptionRadio'
import { PlaybookOptionHeader } from '../Playbook/PlaybookOptionHeader'

const COMPANY_LOOKUP_SIZE = 30

const DocumentSelectorDialog = ({
  trigger,
  dossierDetail,
  initialSelection,
  sourceType,
  onSave,
  open,
  setOpen,
  isDossierFileSelection,
  type,
  initialDocumentSource,
  playbook,
  playbookOption,
  onPlaybookBack,
  onPlaybookOptionChange,
}: {
  trigger?: ReactNode
  dossierDetail?: DossierDetail
  initialSelection?: string[]
  sourceType: SourceType
  onSave?: (
    files: { title: string; id: string }[],
    companiesHouseFiles: DedupedSearchQueryItem[]
  ) => void
  open: boolean
  setOpen: (open: boolean) => void
  isDossierFileSelection?: boolean
  type: DocumentSelectorDialogType
  initialDocumentSource?: DocumentSelectionSource
  playbook?: Playbook
  playbookOption?: string
  onPlaybookBack?: () => void
  onPlaybookOptionChange?: (option: string) => void
}) => {
  const { files: documentFiles, fetchStatus: documentFetchStatus } =
    useSelector((state: RootState) => state.document)

  const {
    uploadQueue: impromptuUploadUploadQueue,
    temporaryDocuments: impromptuUploadTemporaryDocuments,
  } = useSelector((state: RootState) => state.impromptuUpload)
  const dispatch = useDispatch<AppDispatch>()
  const { settings, updateSettings } = useContext(UserContext)

  const [searchText, setSearchText] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [isInitial, setIsInitial] = useState(true)
  const [showSelectedFiles, setShowSelectedFiles] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [prevSearchText, setPrevSearchText] = useState('')
  const [documentSource, setDocumentSource] = useState<DocumentSelectionSource>(
    DocumentSelectionSource.LIBRARY
  )

  const loading = documentFetchStatus === QueryStatus.FETCHING
  const errorFetching = documentFetchStatus === QueryStatus.ERROR_FETCHING
  const resources = documentFiles
  const isDocGen = type === DocumentSelectorDialogType.DOCGEN

  const [forceShowUploadAlert, setForceShowUploadAlert] =
    useState<boolean>(true)

  const [elements, setElements] = useState<FileStructure[]>([])
  const [filteredElements, setFilteredElements] = useState<FileStructure[]>([])
  const [selectedElements, setSelectedElements] = useState<FileStructure[]>([])
  const [selectedSearchElements, setSelectedSearchElements] = useState<
    DedupedSearchQueryItem[]
  >([])
  const [tempSelectedElements, setTempSelectedElements] = useState<
    FileStructure[]
  >([])
  const [tempSelectedSearchElements, setTempSelectedSearchElements] = useState<
    DedupedSearchQueryItem[]
  >([])
  const [companyResults, setCompanyResults] = useState<
    DedupedSearchQueryItem[]
  >([])

  const selectedElementCount =
    tempSelectedElements.length + tempSelectedSearchElements.length

  const uploadedElementCount = Object.keys(
    impromptuUploadTemporaryDocuments
  ).length

  const groupedCompanyResults = useMemo(
    () => groupSearchResultByCompany(companyResults),
    [companyResults]
  )

  const fileLimit = 15
  const fileSizeLimit = 30 * 10 ** 6
  const fileExceedsSizeLimit = tempSelectedElements.some((e) => {
    const document = documentFiles.find((v) => v.document_id === e.document_id)

    return (document?.document_size_bytes || 0) > fileSizeLimit
  })

  const areFilesSelected =
    selectedElements.length > 0 ||
    tempSelectedElements.length > 0 ||
    selectedSearchElements.length > 0 ||
    tempSelectedSearchElements.length > 0

  const showConfirmButton =
    (tempSelectedElements.length <= fileLimit && !fileExceedsSizeLimit) ||
    selectedElements.length > 0 ||
    selectedSearchElements.length > 0 ||
    impromptuUploadUploadQueue.length === 0 ||
    sourceType !== 'ask'

  const shownSelectedElements = useMemo(() => {
    return elements.filter((v) =>
      tempSelectedElements.some(
        (e) =>
          e.internal_element_id === v.internal_element_id ||
          (v.parent_element_internal_ids.at(0) ===
            e.parent_element_internal_ids.at(0) &&
            e.parent_element_internal_ids.length > 0)
      )
    )
  }, [elements, tempSelectedElements])

  const isUploadSelected = useMemo(() => {
    return (
      (isUploadFileEnabled(sourceType) &&
        impromptuUploadUploadQueue.length > 0) ||
      uploadedElementCount > 0
    )
  }, [impromptuUploadUploadQueue, uploadedElementCount, sourceType])

  useEffect(() => {
    if (documentFetchStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [documentFetchStatus, dispatch])

  useEffect(() => {
    if (type === DocumentSelectorDialogType.DOCGEN) {
      updateSettings({
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            sources: {
              ...settings.assistant.sources,
              [sourceType]: {
                ...settings.assistant.sources[sourceType],
                uploadedFiles: [],
              },
            },
          },
        },
      })
    }
  }, [type])

  useEffect(() => {
    if (!open) return

    const filteredDocuments = resources.filter((doc) => {
      const isPdf = doc.document_type_friendly === 'PDF'
      if (!searchText) {
        if (type === DocumentSelectorDialogType.DOCGEN) {
          return isPdf
        }
        return true
      }
      const splitText = searchText.toLowerCase().split(' ')
      const parents = doc.document_source_path_treeview || []

      const result =
        splitText.some((text) =>
          doc.document_name.toLowerCase().includes(text)
        ) ||
        splitText.some((text) =>
          parents.some((v) => v.element_name.toLowerCase().includes(text))
        )
      if (type === DocumentSelectorDialogType.DOCGEN) {
        return result && isPdf
      }
      return result
    })

    const elements = transformResponseDocuments(filteredDocuments)
    setFilteredElements(elements)
  }, [resources, searchText, open, type])

  useEffect(() => {
    if (!open) return

    const selected = getSelectedElements(elements)

    setTempSelectedElements(selected)
  }, [elements, open])

  useEffect(() => {
    if (!open) return

    const readyResources = resources.filter((v) => v.document_is_ready_to_use)

    setElements(transformResponseDocuments(readyResources))
  }, [resources, open])

  useEffect(() => {
    if (!open) return
    if (!isInitial) return
    if (playbook) return

    const selectedFiles = settings.assistant.sources[sourceType].files

    const selected = !isDossierFileSelection
      ? elements.filter((v) =>
          selectedFiles.some(
            (f: { id: string; title: string }) => f.id === v.document_id
          )
        )
      : elements.filter((v) => initialSelection?.includes(v.document_id || ''))

    if (tempSelectedElements.length === 0) {
      setTempSelectedElements(() => selected)
    }

    selected.forEach((v) => {
      if (!v.is_included) {
        toggleElement(v, elements, setElements)
      }
    })

    const selectedCompanyHouseFiles =
      settings.assistant.sources[sourceType].companiesHouseFiles
    setSelectedSearchElements(selectedCompanyHouseFiles)

    if (tempSelectedElements.length === 0) {
      setTempSelectedSearchElements(() => selectedCompanyHouseFiles)
    }

    setSelectedElements(() => selected)
  }, [
    elements,
    open,
    isInitial,
    initialSelection,
    isDossierFileSelection,
    settings.assistant.sources,
    sourceType,
    tempSelectedElements.length,
  ])

  useEffect(() => {
    setSearchText('')
    setCompanyResults([])
  }, [documentSource])

  useEffect(() => {
    if (initialDocumentSource) {
      setDocumentSource(initialDocumentSource)
    }
  }, [initialDocumentSource])

  const getSelectedElements = (elements: FileStructure[]): FileStructure[] => {
    let includedElements = []

    includedElements = elements.filter((v) => {
      return v.is_included && v.element_type === 'file'
    })

    return includedElements
  }

  const saveSettings = () => {
    const validElements = tempSelectedElements.filter((v) => {
      const resource = resources?.find((r) => r.document_id === v.document_id)
      return (
        validFocusedAnalysisFilesTypes.includes(
          resource?.document_type ||
            resource?.document_type_friendly.toLowerCase() ||
            ''
        ) || v.element_type !== 'file'
      )
    })

    if (type === DocumentSelectorDialogType.SETTINGS) {
      const newSource: SourceSettings = {
        ...settings.assistant.sources[sourceType],
        internalSearch:
          isUploadSelected || validElements.length > 0
            ? false
            : settings.assistant.sources[sourceType].internalSearch,
        companiesHouseSearch: tempSelectedSearchElements.some(
          (v) =>
            checkSourceDocumentType(v.id, v.doc_metadata) ===
            SourceDocumentType.COMPANIES_HOUSE
        )
          ? false
          : settings.assistant.sources[sourceType].companiesHouseSearch,
        filingsSearch: tempSelectedSearchElements.some(
          (v) =>
            checkSourceDocumentType(v.id, v.doc_metadata) ===
            SourceDocumentType.FILINGS
        )
          ? false
          : settings.assistant.sources[sourceType].filingsSearch,
        transcriptsSearch: tempSelectedSearchElements.some(
          (v) =>
            checkSourceDocumentType(v.id, v.doc_metadata) ===
            SourceDocumentType.TRANSCRIPTS
        )
          ? false
          : settings.assistant.sources[sourceType].transcriptsSearch,
        focusedAnalysis: true,
        files: isUploadSelected
          ? []
          : validElements.map((v) => {
              return {
                title: v.element_name,
                id: v.document_id || '',
              }
            }),
        companiesHouseFiles: tempSelectedSearchElements,
        uploadedFiles: isUploadSelected
          ? Object.values(impromptuUploadTemporaryDocuments).map((v) => {
              return {
                title: v.document_name || '',
                id: v.document_id || '',
              }
            })
          : [],
      }

      if (!isDossierFileSelection) {
        updateSettings({
          settings: {
            ...settings,
            assistant: {
              ...settings.assistant,
              sources: {
                ...settings.assistant.sources,
                [sourceType]: newSource,
              },
            },
          },
        })
      }
    }

    const ids = validElements
      .map((v) => {
        return {
          title: v.element_name,
          id: v.document_id || '',
        }
      })
      .filter((v): v is { title: string; id: string } => Boolean(v.id))

    onSave?.(ids, tempSelectedSearchElements)
  }

  const discardSettings = () => {
    setOpen(false)
  }

  const getCompaniesHouseResults = async () => {
    const result = await dispatch(
      fetchCompanyResults({
        company_name: searchText,
        sources: ['companies_house'],
        size: COMPANY_LOOKUP_SIZE,
      })
    )
    setHasSearched(true)
    if (isFulfilled(fetchCompanyResults)(result)) {
      const mappedPayload = result.payload.map((v) => {
        const d = v as DedupedSearchQueryItem
        return {
          ...d,
        }
      })

      setCompanyResults(mappedPayload)
    }
  }

  const getFilingResults = async () => {
    const result = await dispatch(
      fetchCompanyResults({
        company_name: searchText,
        sources: ['filings'],
        size: COMPANY_LOOKUP_SIZE,
      })
    )
    setHasSearched(true)
    if (isFulfilled(fetchCompanyResults)(result)) {
      const mappedPayload = result.payload.map((v) => {
        const d = v as DedupedSearchQueryItem
        return {
          ...d,
        }
      })

      setCompanyResults(mappedPayload)
    }
  }

  const getTranscriptResults = async () => {
    const result = await dispatch(
      fetchCompanyResults({
        company_name: searchText,
        sources: ['transcripts'],
        size: COMPANY_LOOKUP_SIZE,
      })
    )
    setHasSearched(true)
    if (isFulfilled(fetchCompanyResults)(result)) {
      const mappedPayload = result.payload.map((v) => {
        const d = v as DedupedSearchQueryItem
        return {
          ...d,
        }
      })

      setCompanyResults(mappedPayload)
    }
  }

  const handleSubmit = () => {
    setPrevSearchText(searchText)
    if (documentSource === DocumentSelectionSource.COMPANIES_HOUSE) {
      getCompaniesHouseResults()
    }

    if (documentSource === DocumentSelectionSource.TRANSCRIPTS) {
      getTranscriptResults()
    }

    if (documentSource === DocumentSelectionSource.FILINGS) {
      getFilingResults()
    }
  }

  const clearUploadedFiles = useCallback(() => {
    if (uploadedElementCount > 0) {
      dispatch(impromptuUploadActions.resetUploadState())
    }
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            ask: {
              ...settings.assistant.sources.ask,
              focusedAnalysis: false,
              internalSearch: true,
              uploadedFiles: [],
            },
          },
        },
      },
    })
  }, [uploadedElementCount])

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(v) => {
          setForceShowUploadAlert(true)
          setOpen(v)
          if (!v) {
            setIsInitial(true)
          }
        }}
      >
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent
          style={{ backgroundImage: `url(${texture})` }}
          className={`!px-10 ${playbook ? '!py-4' : '!py-8'} bg-system-surface ${isUploadSelected ? 'min-h-fit' : '!min-h-[calc(100vh-4rem)]'} !top-8 min-w-[calc(100%-5rem)] tablet:!max-w-[60rem] laptop:!min-w-[80rem] document-selector:!min-w-[105rem] document-selector:!w-[105rem]`}
          dynamicHeight
        >
          <DialogHeader>
            <div className="flex flex-col gap-4">
              {playbook && (
                <DialogTitle className="pb-4 border-b border-system-border-light">
                  <PlaybookOptionHeader
                    playbook={playbook}
                    onBack={() => {
                      setOpen(false)
                      onPlaybookBack?.()
                    }}
                  />
                </DialogTitle>
              )}
              {playbook && playbook.items.length > 1 && (
                <PlaybookOptionRadio
                  playbook={playbook}
                  value={playbookOption}
                  onValueChange={(v) => onPlaybookOptionChange?.(v)}
                />
              )}

              <div className="flex flex-col gap-2">
                {type === DocumentSelectorDialogType.DOCGEN ? (
                  <>
                    <DialogTitle
                      className="text-left"
                      hideCloseButton={Boolean(playbook)}
                    >
                      Select file to use as template
                    </DialogTitle>
                    <DialogDescription className="font-body text-left whitespace-pre-wrap">
                      {
                        'You can only select pdf files for now but we are working on adding support for other file types soon'
                      }
                    </DialogDescription>
                  </>
                ) : (
                  <>
                    <DialogTitle
                      className="text-left"
                      hideCloseButton={Boolean(playbook)}
                    >
                      {sourceType === 'ask'
                        ? 'Select files to analyze'
                        : `Select which documents to use in ${dossierDetail?.subject}`}
                    </DialogTitle>
                    <DialogDescription className="font-body text-left whitespace-pre-wrap">
                      {sourceType === 'ask'
                        ? 'You can select up to 15 files (max 30 MB each) to be used as sources for focused analysis.'
                        : 'Search for the files you want to add or use the suggestions below.'}
                    </DialogDescription>
                  </>
                )}
              </div>

              <div className="flex flex-col gap-8">
                {isUploadSelected && (
                  <>
                    {forceShowUploadAlert &&
                      impromptuUploadUploadQueue.length === 0 && (
                        <CustomAlert
                          variant="info"
                          description={FILE_DROPZONE_INFO_MESSAGE}
                          dismissable={true}
                          onCloseClick={() => {
                            if (forceShowUploadAlert) {
                              setForceShowUploadAlert(false)
                            }
                          }}
                        />
                      )}
                    <div className="w-full flex justify-between">
                      <TypographyBody>Uploaded files</TypographyBody>
                      {impromptuUploadUploadQueue.length === 0 && (
                        <Button
                          variant="tertiary"
                          onClick={() => {
                            clearUploadedFiles()
                          }}
                        >
                          <X className="size-6 shrink-0 stroke-[1.5px]" />
                          Deselect All
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {!isDocGen && UPLOAD_ASK_ENABLED && (
                  <>
                    {selectedElementCount === 0 && (
                      <FileDropzone sourceType={sourceType} />
                    )}

                    {!isUploadSelected && selectedElementCount === 0 && (
                      <div className="flex gap-4">
                        <Divider className="w-full" />
                        <TypographyBody>OR</TypographyBody>
                        <Divider className="w-full " />
                      </div>
                    )}
                  </>
                )}

                {!isUploadSelected && selectedElementCount > 0 && (
                  <div className="flex flex-col gap-6 p-4 border border-system-border-light bg-system-surface-light rounded-lg">
                    <div className="flex items-center">
                      <Button
                        variant="tertiary"
                        onClick={() => setShowSelectedFiles(!showSelectedFiles)}
                      >
                        <div className="flex gap-2 items-center">
                          <TypographyBody className="text-system-body">
                            {`${selectedElementCount} selected ${plural('file', selectedElementCount)}`}
                          </TypographyBody>

                          {showSelectedFiles ? (
                            <ChevronUp className="size-6 shrink-0 stroke-interactive" />
                          ) : (
                            <ChevronDown className="size-6 shrink-0 stroke-interactive" />
                          )}
                        </div>
                      </Button>

                      {areFilesSelected && showConfirmButton && (
                        <div className="flex gap-2 items-center ml-auto">
                          <Button
                            variant="secondary"
                            className="w-fit"
                            onClick={() => {
                              discardSettings()
                            }}
                          >
                            Discard and cancel
                          </Button>
                          {showConfirmButton && (
                            <DialogClose asChild>
                              <Button
                                className="w-fit"
                                onClick={() => saveSettings()}
                              >
                                {type === 'playbook' ? (
                                  <div className="flex gap-2 items-center">
                                    Ask
                                    <ArrowRight />
                                  </div>
                                ) : (
                                  'Confirm selection'
                                )}
                              </Button>
                            </DialogClose>
                          )}
                        </div>
                      )}
                    </div>

                    {selectedElementCount > fileLimit &&
                      sourceType === 'ask' && (
                        <CustomAlert
                          variant="error"
                          description={`You cannot select more than ${fileLimit} files, please deselect some files`}
                        />
                      )}

                    {fileExceedsSizeLimit && sourceType === 'ask' && (
                      <CustomAlert
                        variant="error"
                        title="You cannot select a file larger than 30 MB"
                        description="Please remove the file to continue."
                      />
                    )}

                    {(!isUploadSelected ||
                      type === DocumentSelectorDialogType.PLAYBOOK) &&
                      showSelectedFiles && (
                        <div className="max-h-[50vh] overflow-y-auto">
                          <FileTable
                            elements={elements}
                            shownElements={shownSelectedElements}
                            resources={resources}
                            setElements={(v) => {
                              setElements(v)
                              setIsInitial(false)
                            }}
                            showCheckbox={
                              type === DocumentSelectorDialogType.SETTINGS ||
                              type === DocumentSelectorDialogType.PLAYBOOK
                            }
                            showHeader
                            showFileSize={false}
                            showSourceInsteadOfDirectory
                            showRadio={
                              type === DocumentSelectorDialogType.DOCGEN
                            }
                            disableInvalidFocusedAnalysisFileTypes={
                              type === DocumentSelectorDialogType.SETTINGS
                            }
                            loading={loading}
                            error={errorFetching}
                            openFileOnClick={true}
                          />
                          <SearchFileTable
                            files={tempSelectedSearchElements}
                            onCheckedChange={(file) =>
                              setTempSelectedSearchElements(
                                tempSelectedSearchElements.filter(
                                  (v) => v.id !== file.id
                                )
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </DialogHeader>
          <DialogBody className={`flex flex-col gap-0 pb-2 pt-1`}>
            {!isUploadSelected && (
              <>
                <div
                  className={`mx-auto ${tempSelectedElements.length > 0 ? '' : 'mt-4'}`}
                >
                  <div className="flex gap-2 items-center">
                    <Select
                      value={documentSource}
                      // @ts-expect-error
                      onValueChange={(v) => setDocumentSource(v)}
                    >
                      <SelectTrigger className="w-[15rem] [&_span]:!text-left">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent align="end">
                        <SelectItem value={DocumentSelectionSource.LIBRARY}>
                          Library
                        </SelectItem>
                        <SelectItem
                          value={DocumentSelectionSource.COMPANIES_HOUSE}
                        >
                          Companies House
                        </SelectItem>
                        <SelectItem value={DocumentSelectionSource.FILINGS}>
                          Global companies filings
                        </SelectItem>
                        <SelectItem value={DocumentSelectionSource.TRANSCRIPTS}>
                          Earning call transcripts
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <Input
                      className="w-full mobile:w-[20rem]"
                      placeholder={
                        documentSource === DocumentSelectionSource.LIBRARY
                          ? 'Search by file name'
                          : documentSource === DocumentSelectionSource.FILINGS
                            ? 'Search by filing name'
                            : documentSource ===
                                DocumentSelectionSource.TRANSCRIPTS
                              ? 'Search by transcript name'
                              : 'Search by company name'
                      }
                      isSearch={true}
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value)

                        if (showSuggestions) {
                          setShowSuggestions(false)
                        }

                        if (e.target.value && e.target.value.length > 3) {
                          handleSubmit()
                        }
                      }}
                      onEnter={handleSubmit}
                      isCloseVisible={!!searchText}
                      onCloseClick={() => setSearchText('')}
                    />
                  </div>
                </div>
                {documentSource === DocumentSelectionSource.LIBRARY && (
                  <div className="flex flex-col gap-4">
                    <TypographyBody className="text-system-body">
                      All library files
                    </TypographyBody>
                    <FileTable
                      elements={elements}
                      shownElements={filteredElements}
                      resources={resources}
                      setElements={(v) => {
                        setElements(v)
                        setIsInitial(false)
                      }}
                      showCheckbox={
                        type === DocumentSelectorDialogType.SETTINGS ||
                        type === DocumentSelectorDialogType.PLAYBOOK
                      }
                      showHeader
                      showRadio={type === DocumentSelectorDialogType.DOCGEN}
                      showFileSize={false}
                      disableInvalidFocusedAnalysisFileTypes={
                        type === DocumentSelectorDialogType.SETTINGS
                      }
                      loading={loading}
                      error={errorFetching}
                    />
                  </div>
                )}
                {documentSource !== DocumentSelectionSource.LIBRARY && (
                  <div className="flex flex-col gap-4">
                    {companyResults.length === 0 &&
                      !hasSearched &&
                      documentSource ===
                        DocumentSelectionSource.COMPANIES_HOUSE && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <Lightbulb className="size-6 shrink-0 stroke-interactive" />
                          <TypographyBody className="text-system-body">
                            Start typing a company name to get Companies House
                            results...
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length === 0 &&
                      hasSearched &&
                      documentSource ===
                        DocumentSelectionSource.COMPANIES_HOUSE && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <TypographyBody className="text-system-body">
                            {`No results found for '${prevSearchText}'. Please try searching for a different company.`}
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length === 0 &&
                      !hasSearched &&
                      documentSource === DocumentSelectionSource.FILINGS && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <Lightbulb className="size-6 shrink-0 stroke-interactive" />
                          <TypographyBody className="text-system-body">
                            Start typing a company name to get Global companies
                            filings results...
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length === 0 &&
                      hasSearched &&
                      documentSource === DocumentSelectionSource.FILINGS && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <TypographyBody className="text-system-body">
                            {`No results found for '${prevSearchText}'. Please try searching for a different filing.`}
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length === 0 &&
                      !hasSearched &&
                      documentSource ===
                        DocumentSelectionSource.TRANSCRIPTS && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <Lightbulb className="size-6 shrink-0 stroke-interactive" />
                          <TypographyBody className="text-system-body">
                            Start typing a company name to get Earning call
                            transcripts results...
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length === 0 &&
                      hasSearched &&
                      documentSource ===
                        DocumentSelectionSource.TRANSCRIPTS && (
                        <div className="flex gap-2 items-center mx-auto py-6">
                          <TypographyBody className="text-system-body">
                            {`No results found for '${prevSearchText}'. Please try searching for a different transcript.`}
                          </TypographyBody>
                        </div>
                      )}

                    {companyResults.length > 0 && (
                      <div className="flex flex-col gap-6 mt-2">
                        <div className="border-b border-system-border-light">
                          <div className={`${TableHeaderStyle} flex`}>
                            <div
                              className={`${TableHeadStyle} font-body-strong text-system-primary w-[2.875rem] whitespace-nowrap shrink-0`}
                            ></div>
                            <div
                              className={`${TableHeadStyle} font-body-strong text-system-primary whitespace-nowrap shrink-0 w-[calc(60%-0.375rem)] pl-[3rem]`}
                            >
                              File name
                            </div>

                            <div
                              className={`${TableHeadStyle} font-body-strong text-system-primary whitespace-nowrap shrink-0`}
                            >
                              Updated on
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col gap-4">
                          {Object.values(groupedCompanyResults).map(
                            (results, index) => {
                              return (
                                <FileAnalysisCard
                                  key={`file-analysis-card-${index}`}
                                  files={results}
                                  selectedFiles={tempSelectedSearchElements}
                                  onSelect={(v) =>
                                    setTempSelectedSearchElements(v)
                                  }
                                />
                              )
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </DialogBody>
          {isUploadSelected && showConfirmButton && (
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  variant="secondary"
                  className="w-fit"
                  onClick={clearUploadedFiles}
                >
                  Cancel
                </Button>
              </DialogClose>
              {showConfirmButton && (
                <DialogClose asChild>
                  <Button
                    className="w-fit"
                    disabled={!!impromptuUploadUploadQueue.length}
                    onClick={() => {
                      if (type === 'playbook') {
                        saveSettings()
                      }
                    }}
                  >
                    {type === 'playbook' ? (
                      <div className="flex gap-2 items-center">
                        Ask
                        <ArrowRight />
                      </div>
                    ) : (
                      'Confirm selection'
                    )}
                  </Button>
                </DialogClose>
              )}
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DocumentSelectorDialog
