import { WEB_SERVER_ENDPOINT } from '@/constants'
import { RootState } from '@/store/store'
import {
  DocgenSession,
  DocgenTemplate,
  DocgenTemplateRequest,
  DocgenTheme,
  QueryStatus,
  RequestCreateDraft,
  TemplateCategory,
} from '@/types/types'
import { handleError } from '@/utils/handleError'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchTemplates = createAsyncThunk<
  DocgenTemplate[],
  void,
  { state: RootState }
>(
  'docGen/fetchTemplates',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/template/list`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    const data = await response.json()
    if (!response.ok) {
      return rejectWithValue(data)
    }
    return data
  },
  {
    condition: (_, { getState }) => {
      const { docGen } = getState()
      const { templateListStatus } = docGen
      if (
        templateListStatus === QueryStatus.FETCHING ||
        templateListStatus === QueryStatus.SUCCEEDED
      ) {
        return false
      }
      return true
    },
  }
)

export const fetchTemplate = createAsyncThunk<
  DocgenTemplate,
  string,
  { state: RootState }
>(
  'docGen/fetchTemplate',
  async (templateId, { rejectWithValue }) => {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/template/${templateId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    const data = await response.json()
    if (!response.ok) {
      return rejectWithValue(data)
    }
    return data
  }
  // disable the functionality to avoid doing additional requests for getting a template info.
  // this requires us to track in docgenSlice which template we are currently using/editing to work, because otherwise
  // we would load many different templates and in those cases we need to allow the request to be triggered
  // {
  //   condition: (_, { getState }) => {
  //     const { docGen } = getState();
  //     const { fetchTemplateStatus } = docGen;
  //     if (fetchTemplateStatus === QueryStatus.FETCHING || fetchTemplateStatus === QueryStatus.SUCCEEDED) {
  //       return false;
  //     }
  //     return true;
  //   },
  // }
)

export const createTemplate = createAsyncThunk<
  DocgenTemplateRequest,
  DocgenTemplateRequest,
  { state: RootState }
>('docGen/createTemplate', async (template, { rejectWithValue }) => {
  try {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(template),
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(`Failed to create template: ${response.statusText}`)
    }
    return data
  } catch (error) {
    handleError(error);
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const updateTemplate = createAsyncThunk<
  DocgenTemplate,
  DocgenTemplate,
  { state: RootState }
>('docGen/updateTemplate', async (template, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/template/${template.id}`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(template),
      }
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(`Failed to update template: ${response.statusText}`)
    }
    return data
  } catch (error) {
    handleError(error);
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const deleteTemplate = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('docGen/deleteTemplate', async (templateId, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/template/${templateId}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    if (!response.ok) {
      throw new Error(`Failed to delete template: ${response.statusText}`)
    }
    return
  } catch (error) {
    handleError(error);
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const createTemplateFromDocument = createAsyncThunk<
  DocgenTemplate,
  string,
  { state: RootState }
>(
  'docGen/createTemplateFromDocument',
  async (documentId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${WEB_SERVER_ENDPOINT}/api/docgen/template/generate-from-file/${documentId}`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          `Failed to create template from document: ${response.statusText}`
        )
      }
      return data
    } catch (error) {
    handleError(error);
      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

export const fetchSessions = createAsyncThunk<
  DocgenSession[],
  void,
  { state: RootState }
>(
  'docGen/fetchSessions',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/session/list`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    const data = await response.json()
    if (!response.ok) {
      return rejectWithValue(data)
    }
    return data
  },
  {
    // comment: disabling it for now, but it would be good to add a time-based approach to this say if the request is done in less than 10 seconds
    // condition: (_, { getState }) => {
    //   const { docGen } = getState()
    //   const { sessions } = docGen
    //   if (
    //     sessions.status === QueryStatus.FETCHING ||
    //     sessions.status === QueryStatus.SUCCEEDED
    //   ) {
    //     return false
    //   }
    //   return true
    // },
  }
)

type FetchSessionPayload = {
  sessionId: string
  refetch?: boolean
}

export const fetchSession = createAsyncThunk<
  DocgenSession,
  FetchSessionPayload,
  { state: RootState }
>(
  'docGen/fetchSession',
  async (payload, { rejectWithValue }) => {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/session/${payload.sessionId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    const data = await response.json()
    if (!response.ok) {
      return rejectWithValue(data)
    }
    return data
  },
  {
    condition: (payload, { getState }) => {
      if (payload.refetch) {
        return true
      }
      const { docGen } = getState()
      const { fetchSessionStatus, sessionInUse } = docGen
      if (
        (fetchSessionStatus === QueryStatus.FETCHING ||
          fetchSessionStatus === QueryStatus.SUCCEEDED) &&
        sessionInUse?.id === payload.sessionId
      ) {
        return false
      }
      return true
    },
  }
)

export const createSession = createAsyncThunk<
  DocgenSession,
  Omit<
    DocgenSession,
    | 'id'
    | 'status'
    | 'created_at'
    | 'updated_at'
    | 'send_email_when_ready'
    | 'created_by'
    | 'template'
  >,
  { state: RootState }
>('docGen/createSession', async (session, { rejectWithValue }) => {
  const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(session),
  })
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }
  return data
})

export const updateSession = createAsyncThunk<
  DocgenSession,
  Partial<DocgenSession>,
  { state: RootState }
>('docGen/updateSession', async (session, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/session/${session.id}`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(session),
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }
  return data
})

export const createTheme = createAsyncThunk<
  {
    message: string
    theme: DocgenTheme
  },
  string,
  { state: RootState }
>('docGen/createTheme', async (sessionId, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/theme/generate`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        session_id: sessionId,
      }),
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }
  return data
})

export const fetchThemes = createAsyncThunk<
  DocgenTheme[],
  void,
  { state: RootState }
>('docGen/fetchThemes', async (_, { rejectWithValue }) => {
  const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/theme/list`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }
  return data
})

export const deleteSession = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('docGen/deleteSession', async (sessionId, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/session/${sessionId}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )
    if (!response.ok) {
      throw new Error(`Failed to delete session: ${response.statusText}`)
    }
    return
  } catch (error) {
    handleError(error);
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const fetchTheme = createAsyncThunk<
  DocgenTheme,
  string,
  { state: RootState }
>('docGen/fetchTheme', async (themeId, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/theme/${themeId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }
  return data
})

export const updateTheme = createAsyncThunk<
  DocgenTheme,
  DocgenTheme,
  { state: RootState }
>('docGen/updateTheme', async (theme, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/docgen/theme/${theme.id}`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(theme),
      }
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(`Failed to update template: ${response.statusText}`)
    }
    return data
  } catch (error) {
    handleError(error);
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const createDraft = createAsyncThunk<
  void,
  RequestCreateDraft,
  { state: RootState }
>('docGen/createDraft', async (payload, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/session/generate/draft`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  )
  if (!response.ok) {
    return rejectWithValue({ message: response.statusText })
  }
  return
})

export const fetchTemplateCategories = createAsyncThunk<
  TemplateCategory[],
  void
>('docGen/fetchTemplateCategories', async (_, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/template/category/list`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue({ message: response.statusText })
  }

  return data
})

export const createTemplateCategory = createAsyncThunk<
  TemplateCategory,
  Omit<TemplateCategory, 'updated_at' | 'created_by'>
>('docGen/createTemplateCategory', async (payload, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/template/category`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue({ message: response.statusText })
  }

  return data
})

export const updateTemplateCategory = createAsyncThunk<
  TemplateCategory,
  TemplateCategory
>('docGen/updateTemplateCategory', async (payload, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/template/category/${payload.id}`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue({ message: response.statusText })
  }

  return data
})


export const deleteTemplateCategory = createAsyncThunk<
  void,
  string
>('docGen/deleteTemplateCategory', async (categoryId, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/docgen/template/category/${categoryId}`,
    {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
  if (!response.ok) {
    return rejectWithValue({ message: response.statusText })
  }

  return
})