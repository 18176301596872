import { useCallback, useContext, useEffect, useState } from 'react'
import {
  clearIdentifier,
  clearSettingsSnapshot,
  formatFocusedAnalysisFiles,
  getIdentifier,
  getTimestamp,
  isUploadFileEnabled,
  withSettingsSnapshot,
} from '../../utils/utils'
import { useSocketQuery } from '../../hooks/useSocketQuery'
import {
  AssistantAskMode,
  DESIA_EVENT,
  RequestAssistantAsk,
  ResponseChatStream,
  WebSocketRequestWrapper,
  SourceType,
} from '../../types/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorMessage } from '../ErrorMessage'
import { Conversation } from './Conversation'
import { UserContext } from '../../contexts/UserContext'
import { getAskConnectors, getAskToolEvents, getAskTools } from '@/utils/ask'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { addDossierThread, fetchDossierDetail } from '../Dossier/dossierSlice'
import { actions as assistantActions } from './assistantSlice'
import { getLiveConversationById } from './utils'
import { generateConversationTitle } from './assistantThunk'
import { isFulfilled } from '@reduxjs/toolkit'

export function NewConversation() {
  const { dossier_id } = useParams()

  const assistantStore = useSelector((state: RootState) => state.assistant)
  const dispatch = useDispatch<AppDispatch>()
  const { user, settings } = useContext(UserContext)

  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const dossierDetails = useSelector(
    (state: RootState) => state.dossier.dossierDetails
  )
  const dossier = dossiers.data?.find((v) => v.id === dossier_id)

  const appDispatch = useDispatch<AppDispatch>()

  const [searchParams, setSearchParams] = useSearchParams()
  const [addedThreadToDossier, setAddedThreadToDossier] = useState(false)

  const navigate = useNavigate()
  const requestId = searchParams.get('request_id') || 'unknown'
  const playbook = searchParams.get('playbook')
  const q = searchParams.get('q')
  const mode = searchParams.get('mode') as AssistantAskMode
  const trigger = searchParams.get('trigger')
  const conversationId = searchParams.get('conversation_id')
  const sourceType: SourceType = dossier ? 'dossier' : 'ask'

  const conversation = getLiveConversationById({
    store: assistantStore,
    requestId,
    conversationId: conversationId || '',
  })

  const { executeQuery, state: queryState } = useSocketQuery({
    event: DESIA_EVENT.CHAT_ASK,
    request: {
      requestId: requestId,
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      callback: (response) => {
        const res = response.data as ResponseChatStream // fixme
        dispatch(
          assistantActions.streamingResponse({
            ...res,
            requestId: response.requestId,
            timestamp: response.timestamp,
          })
        )

        if (res.event_type === 'stream-end') {
          handleGenerateTitle(res.conversation_id)
        }
      },
    },
  })

  const handleAsk = useCallback(
    ({
      message,
      mode,
      playbookId,
      playbookVars,
    }: {
      message: string
      mode: AssistantAskMode
      playbookId?: string
      playbookVars?: string[]
    }) => {
      const c = conversation.find(
        // @ts-expect-error
        (c) => !!c.conversationId || !!c.conversation_id
      )
      if (!c) {
        throw new Error('conversationId not found')
      }
      // @ts-expect-error
      const conversationId = c.conversationId || c.conversation_id
      const timestamp = getTimestamp()

      dispatch(
        assistantActions.followUpAsk({
          conversationId,
          requestId: `${requestId}_followup_${conversation.length}`,
          question: message,
          timestamp,
          mode,
          playbook: playbookId
            ? {
                playbook_id: playbookId,
                playbook_vars: playbookVars || [],
              }
            : undefined,
          tool_events: getAskToolEvents(settings.assistant.sources[sourceType]),
        })
      )

      const focusedAnalysisFileIds = settings.assistant.sources[sourceType]
        .focusedAnalysis
        ? settings.assistant.sources[sourceType].files.map((file) => file.id) ||
          []
        : []

      const companiesHouseFileIds =
        settings.assistant.sources[sourceType].companiesHouseFiles || []

      const uploadedFileIds = isUploadFileEnabled(sourceType)
        ? settings.assistant.sources[sourceType].uploadedFiles.map(
            (file) => file.id
          )
        : []

      const fileIds = formatFocusedAnalysisFiles([
        ...focusedAnalysisFileIds,
        ...companiesHouseFileIds,
        ...uploadedFileIds,
      ])

      const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
        requestId,
        timestamp,
        params: {
          message: message,
          conversationId,
          connectorsV2: getAskConnectors(
            settings.assistant.sources[sourceType]
          ),
          model: settings.assistant.parameters?.model,
          seed: settings.assistant.parameters?.seed,
          temperature: settings.assistant.parameters?.temperature,
          systemPrompt: settings.assistant.parameters?.systemPrompt,
          mode,
          focusedAnalysis:
            settings.assistant.sources[sourceType].focusedAnalysis,
          fileIDs: settings.assistant.sources[sourceType].focusedAnalysis
            ? fileIds
            : [],
          ...getAskTools(settings.assistant.sources[sourceType]),
          playbook: playbookId
            ? {
                playbook_id: playbookId,
                playbook_vars: playbookVars || [],
              }
            : undefined,
          exclude_domains:
            settings.assistant.sources[sourceType].webSearch &&
            settings.assistant.sources[sourceType].excludedWebSearch
              ? user?.preferences?.[sourceType]?.excluded_websites
              : undefined,
        },
      }

      withSettingsSnapshot({
        event: DESIA_EVENT.CHAT_ASK,
        conversationId: conversationId,
        settings: settings,
        callbackFn: () => {
          executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request,
          })
        },
      })
    },
    [
      conversation,
      requestId,
      settings.assistant.parameters,
      settings.assistant.sources,
      sourceType,
      dispatch,
      executeQuery,
      playbook,
    ]
  )

  const handleGenerateTitle = useCallback(
    async (conversationId: string) => {
      if (!conversationId) return
      const result = await dispatch(generateConversationTitle(conversationId))

      if (isFulfilled(generateConversationTitle)(result)) {
        dispatch(
          assistantActions.chatRename({
            conversationId: result.payload.conversationId,
            title: result.payload.title,
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (conversationId) {
      navigate(`/assistant/conversation/${conversationId}`)
      return
    }

    if (requestId) {
      const identifiers = getIdentifier(requestId)
      if (identifiers) {
        navigate(`/assistant/conversation/${identifiers.conversationId}`)
        return
      }
    }

    if (q && trigger) {
      const focusedAnalysisFileIds = settings.assistant.sources[sourceType]
        .focusedAnalysis
        ? settings.assistant.sources[sourceType].files.map((file) => file.id) ||
          []
        : []

      const companiesHouseFileIds =
        settings.assistant.sources[sourceType].companiesHouseFiles || []

      const uploadedFileIds = isUploadFileEnabled(sourceType)
        ? settings.assistant.sources[sourceType].uploadedFiles.map(
            (file) => file.id
          )
        : []

      const fileIds = formatFocusedAnalysisFiles([
        ...focusedAnalysisFileIds,
        ...companiesHouseFileIds,
        ...uploadedFileIds,
      ])

      const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
        requestId,
        timestamp: getTimestamp(),
        params: {
          message: q,
          connectorsV2: getAskConnectors(
            settings.assistant.sources[sourceType]
          ),
          model: settings.assistant.parameters?.model,
          seed: settings.assistant.parameters?.seed,
          temperature: settings.assistant.parameters?.temperature,
          systemPrompt: settings.assistant.parameters?.systemPrompt,
          mode: mode || 'simple',
          focusedAnalysis:
            settings.assistant.sources[sourceType].focusedAnalysis,
          fileIDs: settings.assistant.sources[sourceType].focusedAnalysis
            ? fileIds
            : [],
          ...getAskTools(settings.assistant.sources[sourceType]),
          playbook: playbook ? JSON.parse(playbook) : undefined,
          exclude_domains:
            settings.assistant.sources[sourceType].webSearch &&
            settings.assistant.sources[sourceType].excludedWebSearch
              ? user?.preferences?.[sourceType]?.excluded_websites
              : undefined,
        },
      }

      withSettingsSnapshot({
        event: DESIA_EVENT.CHAT_ASK,
        conversationId: requestId,
        settings: settings,
        callbackFn: () => {
          executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request,
          })
        },
      })

      withSettingsSnapshot({
        event: DESIA_EVENT.CHAT_ASK,
        conversationId: 'home',
        settings: settings,
      })

      setSearchParams((prev) => {
        prev.delete('trigger')
        return prev
      })
    }

    return () => {
      const identifiers = getIdentifier(requestId)
      if (identifiers) {
        clearIdentifier(requestId)

        withSettingsSnapshot({
          event: DESIA_EVENT.CHAT_ASK,
          conversationId: identifiers.conversationId,
          settings: settings,
          callbackFn: () => {
            clearSettingsSnapshot(DESIA_EVENT.CHAT_ASK, requestId)
          },
        })

        return
      }
    }
  }, [])

  useEffect(() => {
    const conversationId = conversation.at(-1)?.conversationId

    if (!conversationId || !dossier_id) return

    if (!addedThreadToDossier) {
      setAddedThreadToDossier(true)
      appDispatch(
        addDossierThread({
          threadId: conversationId,
          dossierId: dossier_id,
        })
      )
    }
  }, [conversation])

  useEffect(() => {
    if (!dossier) return
    dispatch(fetchDossierDetail(dossier.id))
  }, [dossier])

  useEffect(() => {
    document.title = 'Desia AI - Ask'
  }, [])

  if (!requestId) {
    return (
      <ErrorMessage
        message={`We failed to ask that question: missing request_id or question`}
      />
    )
  }

  if (!conversation.length) {
    return <ErrorMessage message="Something unexpected occurred, try again" />
  }

  return (
    <Conversation
      handleAsk={handleAsk}
      conversation={conversation}
      queryState={queryState}
      sources={settings.assistant.sources[sourceType]}
      dossier={dossier}
      dossierDetail={dossierDetails[dossier?.id || '']?.data || undefined}
    />
  )
}
