import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { Input } from '../ui/input'
import { TypographyBody } from '../ui/Typography'
import { ChevronDown, ChevronUp, Lightbulb } from 'lucide-react'
import { useDomainSearch } from '@/hooks/useDomainSearch'
import { ASYNC_STATUS, SourceType, UserPreference } from '@/types/types'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import Divider from '../ui/divider'
import { getHostname, getIconSrc, plural } from '@/utils/utils'
import { Checkbox } from '../ui/checkbox'
import { Button } from '../ui/button'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { updateUserPreferences } from '@/features/userSlice'
import { UserContext } from '@/contexts/UserContext'

const DomainSelectorDialog = ({
  open,
  setOpen,
  sourceType,
}: {
  open: boolean
  setOpen: (value: boolean) => void
  sourceType: SourceType
}) => {
  const { refetchUser, user, settings, updateSettings } =
    useContext(UserContext)
  const userPreferences = user?.preferences?.[sourceType]

  const dispatch = useDispatch<AppDispatch>()

  const { query, searchResults, searchStatus, handleSearchChange } =
    useDomainSearch()

  const [selectedDomains, setSelectedDomains] = useState<Set<string>>(new Set())

  const [isShowSelectedDomains, setIsShowSelectedDomains] =
    useState<boolean>(false)

  const selectedDomainAmount = selectedDomains.size
  const isDomainSelected = selectedDomainAmount > 0
  const isUserPreferenceDomainSelected =
    (userPreferences?.excluded_websites?.length ?? 0) > 0

  const toggleShowSelectedDomains = () => {
    if (!isDomainSelected) {
      setIsShowSelectedDomains(false)
      return
    }
    setIsShowSelectedDomains(!isShowSelectedDomains)
  }
  const handleCheckboxChange = (domain: string) => {
    setSelectedDomains((prevSelectedDomains) => {
      const newSelectedDomains = new Set(prevSelectedDomains)
      if (newSelectedDomains.has(domain)) {
        newSelectedDomains.delete(domain)
      } else {
        newSelectedDomains.add(domain)
      }
      return newSelectedDomains
    })
  }

  const handleSubmit = async () => {
    const selectedExcludedDomains = [...selectedDomains.values()]
    const preference: UserPreference = {
      [sourceType]: {
        excluded_websites: selectedExcludedDomains,
        prioritised_websites: [] as string[],
      },
    }
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              webSearch: selectedExcludedDomains.length > 0,
              excludedWebSearch: selectedExcludedDomains.length > 0,
            },
          },
        },
      },
    })
    await dispatch(updateUserPreferences(preference))
    refetchUser()
    setOpen(false)
  }

  const handleDiscard = () => {
    setSelectedDomains(new Set(userPreferences?.excluded_websites))
    setOpen(false)
  }

  useEffect(() => {
    if (userPreferences?.excluded_websites) {
      setSelectedDomains(new Set(userPreferences?.excluded_websites))
    }
  }, [userPreferences?.excluded_websites])

  return (
    <Dialog open={open}>
      <DialogContent
        className="min-w-[calc(100%-5rem)] tablet:!max-w-[60rem] laptop:!min-w-[57.85rem] flex flex-col gap-6"
        onInteractOutside={() => {
          handleDiscard()
          setOpen(false)
        }}
      >
        <DialogHeader>
          <DialogTitle
            onClose={() => {
              handleDiscard()
              setOpen(false)
            }}
          >
            Exclude domains
          </DialogTitle>
          <DialogDescription>
            <TypographyBody className="text-system-body">
              You can select which domains you’d like to exclude from research
            </TypographyBody>
          </DialogDescription>
        </DialogHeader>
        <DialogBody className="flex flex-col w-full h-fit items-center justify-start gap-6">
          {(isDomainSelected || isUserPreferenceDomainSelected) && (
            <div className="w-full flex flex-col gap-6 p-4 border border-system-border-light bg-system-surface-light rounded-lg">
              <div className="flex items-center">
                <Button
                  variant="tertiary"
                  onClick={() => {
                    toggleShowSelectedDomains()
                  }}
                >
                  <div className="flex gap-2 items-center cursor-pointer">
                    <TypographyBody className="text-system-body">
                      {`${selectedDomainAmount} selected ${plural('domain', selectedDomainAmount)}`}
                    </TypographyBody>

                    {isShowSelectedDomains ? (
                      <ChevronUp className="size-6 shrink-0 stroke-interactive" />
                    ) : (
                      <ChevronDown className="size-6 shrink-0 stroke-interactive" />
                    )}
                  </div>
                </Button>
                {(isDomainSelected || isUserPreferenceDomainSelected) && (
                  <div className="flex gap-2 items-center ml-auto">
                    <Button
                      variant="secondary"
                      className="w-fit"
                      onClick={() => {
                        handleDiscard()
                      }}
                    >
                      Discard and cancel
                    </Button>
                    <DialogClose asChild>
                      <Button
                        className="w-fit"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        Confirm selection
                      </Button>
                    </DialogClose>
                  </div>
                )}
              </div>
              {isShowSelectedDomains && (
                <div className="w-full grid grid-cols-1 max-h-[40vh] overflow-y-auto">
                  {[...selectedDomains.values()].map((domain, i) => {
                    const hostname = getHostname(`https://${domain}`)
                    const src = getIconSrc(hostname)

                    return (
                      <div key={`domain-${i}`} className="w-full h-[3.4285rem]">
                        <div className="w-full h-full flex items-center">
                          <div className="w-fit flex gap-3 h-full items-center py-[0.4285rem] px-[0.85rem]">
                            <Checkbox
                              className="w-[1.4285rem] h-[1.4285rem]"
                              checked={selectedDomains.has(domain)}
                              onClick={() => {
                                handleCheckboxChange(domain)
                              }}
                            />
                          </div>
                          <div className="w-full flex gap-3 h-full items-center py-[0.4285rem] px-[0.85rem]">
                            <div className="rounded-sm h-[1.715rem] w-[1.715rem] overflow-hidden">
                              <img
                                src={src}
                                alt={hostname}
                                className="w-full h-full aspect-square "
                              />
                            </div>
                            <TypographyBody className=" h-full items-center flex">
                              {domain}
                            </TypographyBody>
                          </div>
                        </div>
                        {selectedDomainAmount - 1 !== i && <Divider />}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}

          <section className="w-full overflow-y-auto flex flex-col gap-2 justify-start items-center gap-6 pt-2">
            <Input
              className="w-full h-fit mobile:w-[20rem]"
              placeholder="e.g. wikipedia"
              isSearch={true}
              value={query}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSearchChange(e.target.value)
              }}
              onEnter={handleSubmit}
              isCloseVisible={!!query}
              onCloseClick={() => {
                handleSearchChange('')
              }}
            />
            <section className="w-full h-full flex flex-col justify-center items-start min-h-[5.285rem] ">
              {Boolean(
                query.length === 0 && searchStatus === ASYNC_STATUS.idle
              ) ? (
                <div className="h-full w-full flex gap-2 justify-center items-center">
                  <Lightbulb className="stroke-[1.5px] stroke-system-primary" />
                  <TypographyBody className="text-system-body">
                    Search for a domain name to exclude websites belonging to
                    that domain
                  </TypographyBody>
                </div>
              ) : (
                <>
                  {searchStatus === ASYNC_STATUS.loading && (
                    <div className="h-full w-full flex items-center justify-center text-system-body">
                      <TypographyBody>Loading...</TypographyBody>
                    </div>
                  )}

                  {searchStatus === ASYNC_STATUS.error && (
                    <TypographyBody className="text-system-body">
                      Error fetching results
                    </TypographyBody>
                  )}

                  {searchStatus === ASYNC_STATUS.success &&
                    searchResults.length === 0 &&
                    query.length > 0 && (
                      <div className="h-full w-full flex items-center justify-center text-system-body">
                        <TypographyBody>No results found</TypographyBody>
                      </div>
                    )}

                  {searchStatus === ASYNC_STATUS.success &&
                    searchResults.length > 0 && (
                      <div className="w-full h-full bg-system-secondary border border-solid border-[1px] border-system-border-light rounded-lg overflow-y-auto">
                        <div className="w-full grid grid-cols-1 p-[0.5715rem] pb-[1,145rem]">
                          {searchResults.map((domain, i) => {
                            const hostname = getHostname(`https://${domain}`)
                            const src = getIconSrc(hostname)
                            return (
                              <div
                                key={`domain-${i}`}
                                className="w-full h-[3.4285rem]"
                              >
                                <div className="w-full h-full flex items-center">
                                  <div className="w-fit flex gap-3 h-full items-center py-[0.4285rem] px-[0.85rem]">
                                    <Checkbox
                                      className="w-[1.4285rem] h-[1.4285rem]"
                                      checked={selectedDomains.has(domain)}
                                      onClick={() => {
                                        handleCheckboxChange(domain)
                                      }}
                                    />
                                  </div>
                                  <div className="w-full flex gap-3 h-full items-center py-[0.4285rem] px-[0.85rem]">
                                    <div className="rounded-sm h-[1.715rem] w-[1.715rem] overflow-hidden">
                                      <img
                                        src={src}
                                        className="w-full h-full aspect-square "
                                      />
                                    </div>
                                    <TypographyBody className=" h-full items-center flex">
                                      {hostname}
                                    </TypographyBody>
                                  </div>
                                </div>
                                {searchResults.length - 1 !== i && <Divider />}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                </>
              )}
            </section>
          </section>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default DomainSelectorDialog
