import { WEB_SERVER_ENDPOINT } from '@/constants'
import { RootState } from '@/store/store'
import {
  Playbook,
  PlaybookItem,
  QueryStatus,
  RequestCreatePlaybook,
  ResponseCreatePlaybook,
} from '@/types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

// todo: handle errors
export const fetchPlaybooks = createAsyncThunk<
  Playbook[],
  void,
  { state: RootState }
>(
  'playbook/fetchPlaybooks',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/playbook/list`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
    const data = await response.json()
    if (!response.ok) {
      return rejectWithValue(data)
    }

    return data
  },
  {
    condition: (_, { getState }) => {
      const { playbook } = getState()
      const { status } = playbook.playbooks
      if (status === QueryStatus.FETCHING) {
        return false
      }
      return true
    },
  }
)

export const createPlaybook = createAsyncThunk<
  ResponseCreatePlaybook,
  RequestCreatePlaybook,
  { state: RootState }
>('playbook/createPlaybook', async (payload, { rejectWithValue }) => {
  const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/playbook`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }

  return data
})

export const updatePlaybook = createAsyncThunk<
  void,
  PlaybookItem,
  { state: RootState }
>('playbook/createPlaybook', async (payload, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/playbook/${payload.id}`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  )
  const data = await response.json()
  if (!response.ok) {
    return rejectWithValue(data)
  }

  return data
})

export const deletePlaybook = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('playbook/deletePlaybook', async (payloadId, { rejectWithValue }) => {
  const response = await fetch(
    `${WEB_SERVER_ENDPOINT}/api/playbook/${payloadId}`,
    {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
  if (!response.ok) {
    return rejectWithValue({ message: 'Failed to delete playbook' })
  }

  return
})
