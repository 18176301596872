import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { getChartCitation } from './utils'
import { formatDisplayNumber } from '@/utils/utils'
import { useMemo, useRef } from 'react'
import { ChartHeader } from './ChartHeader'
import { ChartTooltip } from './ChartTooltip'
import { REM, Color } from '@/constants'
import { ApexChartsResponseChartProps } from '@/types/types'

const ResponseHorizontalBarChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  isFormatted,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const barAmount = series.reduce((a, b) => a + b.data.length, 0)
  const fontSize = 14
  const height = barAmount < 12 ? 24 : 48

  const max = useMemo(() => {
    return Math.max(
      ...series
        .filter(
          (item) =>
            Array.isArray(item.data) &&
            item.data.every((val) => typeof val === 'number')
        )
        .flatMap((item) => item.data as number[])
    )
  }, [series])

  const barOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: 'bar',
      events: {
        ...options.chart?.events,
        mounted: (chart) => {
          if (
            openedCitation &&
            openedCitation.chart_table_id === id &&
            !openedCitation.isChartHeaderCitation
          ) {
            chart.toggleDataPointSelection(
              openedCitation.index_y,
              openedCitation.index_x
            )
          }
        },
        dataPointSelection: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: categories,
    },
    yaxis: {
      ...options.yaxis,
      labels: {
        formatter: (val: number) => {
          const isDate = !isNaN(new Date(val).getTime())
          return isFormatted && !isDate ? formatDisplayNumber(val) : `${val}`
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          colors: Color['system-body'],
        },
      },
      show: true,
      max: max + max * 0.1, // pad right
    },
    dataLabels: {
      enabled: true,
      formatter: (val: string) => {
        return formatDisplayNumber(val) as string
      },
      offsetX: 5,
      textAnchor: 'start',
      style: {
        fontSize: `${fontSize}px`,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        colors: ['#0a0a0a'],
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        horizontal: true,
        hideZeroBarsWhenGrouped: false,
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          orientation: 'horizontal',
          position: 'top',
          total: {
            enabled: true,
            style: {
              fontSize: `${fontSize}px`,
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
            },
          },
        },
      },
    },
  }

  return (
    <div className={`p-4 ${compact ? 'mt-8' : 'mt-10'}`}>
      <div
        id={`chart-container-${id}`}
        className={`flex flex-col gap-4 relative group`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}

        <div className="relative w-full" style={{ height: `${height}rem` }}>
          <div
            className={`absolute max-w-full w-full max-w-[calc(100vw-2rem)] tablet:max-w-[calc(100vw-4rem)] laptop:max-w-[calc(100vw-11.25rem)] tablet:left-[50%] tablet:-translate-x-[50%] overflow-x-auto laptop:overflow-x-visible overflow-y-visible h-[18.75rem]`}
          >
            <div
              ref={ref}
              id={`chart-${id}`}
              className={`w-full h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}
            >
              <Chart
                options={barOptions}
                series={series}
                type="bar"
                width="100%"
                height={height * REM}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResponseHorizontalBarChart
