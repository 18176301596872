import { WEB_SERVER_ENDPOINT } from "@/constants";
import { IntegrationGreenlistedStructure, RequestIntegrationGreenlistedApprove, RequestIntegrationGreenlistedDelete, RequestIntegrationGreenlistedUpsert, ResponseGenerateAuthorizationURL, ResponseUserIntegrations } from "@/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

// todo: handle errors
export const greenlistingUpsert = createAsyncThunk<IntegrationGreenlistedStructure[], RequestIntegrationGreenlistedUpsert>(
    'integration/greenlistingUpsert',
    async (credential: RequestIntegrationGreenlistedUpsert, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/greenlisted/synchronization/upsert`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(credential)
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const greenlistingApprove = createAsyncThunk<void, RequestIntegrationGreenlistedApprove>(
    'integration/greenlistingApprove',
    async (credential: RequestIntegrationGreenlistedApprove, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/greenlisted/synchronization/approve`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(credential)
        });
        if (!response.ok) {
            return rejectWithValue(response)
        }
        return
    },
)

export const greenlistingDelete = createAsyncThunk<void, RequestIntegrationGreenlistedDelete>(
    'integration/greenlistingDelete',
    async (credential: RequestIntegrationGreenlistedDelete, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/greenlisted/synchronization/delete`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(credential)
        });
        if (!response.ok) {
            return rejectWithValue(response)
        }
        return
    },
)

export const greenlistingCleanup = createAsyncThunk<void, number>(
    'integration/greenlistingCleanup',
    async (integrationId: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/greenlisted/${integrationId}/cleanup`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            return rejectWithValue(response)
        }
        return
    },
)

export const disconnectAllMicrosoft = createAsyncThunk<void, boolean>(
    'integration/disconnectAllMicrosoft',
    async (confirmed: boolean, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/msft/disconnect-all`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                client_confirmed: confirmed
            })
        });
        if (!response.ok) {
            return rejectWithValue(response)
        }
        return
    },
)

export const fetchGreenlistingStructures = createAsyncThunk<IntegrationGreenlistedStructure[], number>(
    'integration/fetchGreenlistingStructures',
    async (integrationId: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/greenlisted/${integrationId}/structures`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const fetchAdminConsent = createAsyncThunk<{ url: string }, void>(
    'integration/fetchAdminConsent',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/msft/admin-consent`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });

        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const fetchUserIntegrations = createAsyncThunk<ResponseUserIntegrations, void>(
    'integration/fetchUserIntegrations',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/list-available-for-user`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const generateAuthorizationURL = createAsyncThunk<ResponseGenerateAuthorizationURL, number>(
    'integration/generateAuthorizationURL',
    async (integrationId: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/msft/generate-authorization-url`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                integration_id: integrationId
            })
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)


export const disconnectUserIntegration = createAsyncThunk<void, number>(
    'integration/disconnectUserIntegration',
    async (integrationId: number, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/integration/user/disconnect`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                integration_id: integrationId
            })
        });
        if (!response.ok) {
            return rejectWithValue(response)
        }
        return
    },
)