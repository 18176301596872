import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../contexts/UserContext'
import {
  ASYNC_STATUS,
  DatabaseConnector,
  DocumentSelectionSource,
  DocumentSelectorDialogType,
  DossierDetail,
  IntegrationCode,
  QueryStatus,
  ResponseDocument,
  SourceDocumentType,
  SourceSettings,
  SourceType,
  UserIntegration,
} from '../../types/types'
import DocumentSelectorDialog from './DocumentSelectorDialog'
import {
  checkSourceDocumentType,
  checkUserFlag,
  getIntegrationName,
  isUploadFileEnabled,
  plural,
} from '@/utils/utils'
import { OptionDropdownMenuItem } from '../ui/option-dropdown-menu'
import {
  Ban,
  Database,
  FileSearch,
  Globe,
  LibraryBig,
  MessageCircleMore,
  X,
} from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { listDocuments } from '../../components/Document/documentThunk'
import { StateChip } from '../StateChip'
import { Checkbox } from '../ui/checkbox'
import { getDatabaseIcon, getIntegrationIcon } from '@/utils/components'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { fetchUserIntegrations } from '../Integration/integrationThunk'
import { Button, secondaryStyle } from '../ui/button'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'
import { actions as impromptuUploadActions } from '../Document/impromptuUploadSlice'
import AskUploadFileRemoveDialog from './AskUploadFileRemoveDialog'
import DomainSelectorDialog from './DomainSelectorDialog'

const getDatabaseDropdownText = (settings: SourceSettings) => {
  if (settings.companiesHouseFiles && settings.companiesHouseFiles.length > 0) {
    if (settings.financialDataSearch) {
      return `FD + ${settings.companiesHouseFiles.length} ${plural('file', settings.companiesHouseFiles.length)}`
    }

    return `${settings.companiesHouseFiles.length} ${plural('file', settings.companiesHouseFiles.length)}`
  }

  const selectedSources = [
    settings.financialDataSearch,
    settings.companiesHouseSearch,
    settings.filingsSearch,
    settings.transcriptsSearch,
  ].filter((v) => v)

  if (selectedSources.length === 4) {
    return 'Databases'
  }

  if (selectedSources.length > 0) {
    return `${selectedSources.length} ${plural('source', selectedSources.length)}`
  }

  return 'Databases'
}

export function SourceSelector({
  dossierDetail,
  sourceType,
}: {
  dossierDetail?: DossierDetail
  sourceType: SourceType
}) {
  const { user, settings, updateSettings } = useContext(UserContext)
  const { files: documentFiles, fetchStatus: documentFetchStatus } =
    useSelector((state: RootState) => state.document)

  const { status: userIntegrationsStatus, data: userIntegrationsData } =
    useSelector((state: RootState) => state.integration.userIntegrations)

  const { uploadQueue: impromptuUploadUploadQueue } = useSelector(
    (state: RootState) => state.impromptuUpload
  )

  const dispatch = useDispatch<AppDispatch>()

  const [shownDocumentSelectorSource, setShownDocumentSelectorSource] =
    useState<DocumentSelectionSource | null>(null)
  const [isUploadremoveDialogOpen, setIsUploadRemoveDialogOpen] =
    useState<boolean>(false)
  const [isDomainSelectorDialogOpen, setIsDomainSelectorDialogOpen] =
    useState<boolean>(false)

  const [showCommunicationDropdown, setShowCommunicationDropdown] =
    useState(false)
  const [showDatabaseDropdown, setShowDatabaseDropdown] = useState(false)
  const [updatedDefaultDossierFiles, setUpdatedDefaultDossierFiles] =
    useState(false)

  const sources = settings.assistant.sources[sourceType]

  const excludedDomains =
    user?.preferences?.[sourceType]?.excluded_websites ?? []

  const selectedFiles = sources.files || []
  const uploadedFiles = sources.uploadedFiles || []
  const selectedDatabaseFiles = sources.companiesHouseFiles || []
  const companiesHouseFiles = useMemo(
    () =>
      sources.companiesHouseFiles.filter(
        (v) =>
          checkSourceDocumentType(v.id, v.doc_metadata) ===
          SourceDocumentType.COMPANIES_HOUSE
      ),
    [sources.companiesHouseFiles]
  )
  const filingsFiles = useMemo(
    () =>
      sources.companiesHouseFiles.filter(
        (v) =>
          checkSourceDocumentType(v.id, v.doc_metadata) ===
          SourceDocumentType.FILINGS
      ),
    [sources.companiesHouseFiles]
  )
  const transcriptsFiles = useMemo(
    () =>
      sources.companiesHouseFiles.filter(
        (v) =>
          checkSourceDocumentType(v.id, v.doc_metadata) ===
          SourceDocumentType.TRANSCRIPTS
      ),
    [sources.companiesHouseFiles]
  )

  const selectedFileResources = (
    selectedFiles?.map((v) => {
      return documentFiles?.find((f) => f.document_id === v.id)
    }) || []
  ).filter((v: ResponseDocument | undefined): v is ResponseDocument =>
    Boolean(v)
  )

  const dossierFileResources = (
    dossierDetail?.sources.map((v) => {
      return documentFiles?.find((f) => f.document_id === v.resource_id)
    }) || []
  ).filter((v): v is ResponseDocument => Boolean(v))

  const dossierFiles = dossierFileResources.map((v) => {
    return {
      title: v.document_name,
      id: v.document_id,
    }
  })

  const isUploadSelected = Boolean(sources.uploadedFiles?.length > 0)

  const isFileSelectedForAnalysis = Boolean(
    selectedFiles.length > 0
      ? settings.assistant.sources[sourceType].focusedAnalysis
      : false
  )
  const isWebSelected = Boolean(sources.webSearch)
  const isExcludedWebSelected = Boolean(sources.excludedWebSearch)
  const isInternalSearchSelected = Boolean(sources.internalSearch)
  const isFocusedAnalysisSelected = Boolean(sources.focusedAnalysis)
  const isCommunicationSelected = Boolean(
    sources.outlookSearch || sources.teamsSearch
  )
  const allCommunicationSelected = Boolean(
    sources.outlookSearch && sources.teamsSearch
  )

  const isFinancialDataSelected = Boolean(sources.financialDataSearch)

  const isCompaniesHouseSelected = Boolean(
    sources.companiesHouseSearch || companiesHouseFiles.length > 0
  )

  const isFilingsSelected = Boolean(
    sources.filingsSearch || filingsFiles.length > 0
  )

  const isTranscriptsSelected = Boolean(
    sources.transcriptsSearch || transcriptsFiles.length > 0
  )

  const isDatabaseSelected = Boolean(
    sources.financialDataSearch ||
      sources.companiesHouseSearch ||
      sources.filingsSearch ||
      sources.transcriptsSearch
  )

  const onlyDossierFilesSelected =
    selectedFileResources.length === dossierFileResources.length &&
    selectedFiles.every((v: { title: string; id: string }) =>
      dossierFileResources?.find((r) => r.document_id === v.id)
    )

  const personalIntegrations = userIntegrationsData?.filter(
    (v) => v.user_level_integration && v.integration_enabled_by_user
  )
  const forceShowIntegrationsConnectors = checkUserFlag(
    'debug: force show integration connectors'
  )

  const toggleUploadConnector = () => {
    if (!isUploadremoveDialogOpen) {
      setIsUploadRemoveDialogOpen(true)
      return
    }
    dispatch(impromptuUploadActions.resetUploadState())
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              focusedAnalysis:
                (Boolean(dossierDetail) && dossierFiles.length > 0) ||
                selectedFiles.length > 0,
              files: isInternalSearchSelected ? [] : dossierFiles,
              ...(isUploadFileEnabled(sourceType) && { uploadedFiles: [] }),
            },
          },
        },
      },
    })
  }

  const toggleWebConnector = (override: boolean) => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              webSearch: override,
              excludedWebSearch: false,
            },
          },
        },
      },
    })
  }

  const toggleExcludedWebConnector = (override: boolean) => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              excludedWebSearch: override,
            },
          },
        },
      },
    })
  }

  const toggleLibraryConnector = (override: boolean) => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              // when focusedAnalysis but toggled, just shut connectors down
              internalSearch: override,
              focusedAnalysis:
                Boolean(dossierDetail) && dossierFiles.length > 0,
              // files: isInternalSearchSelected ? [] : dossierFiles,
              ...(isUploadFileEnabled(sourceType) && { uploadedFiles: [] }),
            },
          },
        },
      },
    })
  }

  const toggleFinancialDataConnector = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              financialDataSearch: !isFinancialDataSelected,
            },
          },
        },
      },
    })
  }

  const toggleCompaniesHouseConnector = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              companiesHouseSearch: !isCompaniesHouseSelected,
              companiesHouseFiles: [...filingsFiles, ...transcriptsFiles],
              focusedAnalysis:
                isFileSelectedForAnalysis ||
                [...filingsFiles, ...transcriptsFiles].length > 0,
            },
          },
        },
      },
    })
  }

  const toggleCommunicationConnector = () => {
    const isTeamsEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.TEAMS
    )
    const isOutlookEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.OUTLOOK
    )

    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              teamsSearch: isTeamsEnabled ? !isCommunicationSelected : false,
              outlookSearch: isOutlookEnabled
                ? !isCommunicationSelected
                : false,
            },
          },
        },
      },
    })
  }

  const toggleDatabaseConnector = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              focusedAnalysis: isFileSelectedForAnalysis,
              financialDataSearch: !isDatabaseSelected,
              companiesHouseSearch: !isDatabaseSelected,
              filingsSearch: !isDatabaseSelected,
              transcriptsSearch: !isDatabaseSelected,
              companiesHouseFiles: [],
            },
          },
        },
      },
    })
  }

  const toggleFilingsConnector = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              filingsSearch: !isFilingsSelected,
              companiesHouseFiles: [
                ...companiesHouseFiles,
                ...transcriptsFiles,
              ],
              focusedAnalysis:
                isFileSelectedForAnalysis ||
                [...companiesHouseFiles, ...transcriptsFiles].length > 0,
            },
          },
        },
      },
    })
  }

  const toggleTranscriptsConnector = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources[sourceType],
              transcriptsSearch: !isTranscriptsSelected,
              companiesHouseFiles: [...companiesHouseFiles, ...filingsFiles],
              focusedAnalysis:
                isFileSelectedForAnalysis ||
                [...companiesHouseFiles, ...filingsFiles].length > 0,
            },
          },
        },
      },
    })
  }

  const getIntegrationDescriptionText = (integration: UserIntegration) => {
    switch (integration.integration_code_name) {
      case IntegrationCode.OUTLOOK:
        return 'Emails including attachment files'
      case IntegrationCode.TEAMS:
        return 'Messages and call transcripts'
    }
  }

  const setDossierDefaultFiles = useCallback(() => {
    if (!updatedDefaultDossierFiles && dossierDetail) {
      updateSettings({
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            sources: {
              ...settings.assistant.sources,
              [sourceType]: {
                ...settings.assistant.sources[sourceType],
                files: dossierFiles,
                focusedAnalysis: dossierFiles.length > 0,
              },
            },
          },
        },
      })

      setUpdatedDefaultDossierFiles(true)
    }
  }, [
    dossierFiles,
    updatedDefaultDossierFiles,
    settings,
    updateSettings,
    sourceType,
    dossierDetail,
  ])

  useEffect(() => {
    if (documentFetchStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [documentFetchStatus, dispatch])

  useEffect(() => {
    if (userIntegrationsStatus === ASYNC_STATUS.idle) {
      dispatch(fetchUserIntegrations())
    }
  }, [dispatch, userIntegrationsStatus])

  useEffect(() => {
    setDossierDefaultFiles()
  }, [setDossierDefaultFiles])

  const libraryLabel = useCallback(
    (overrides?: {
      uploading?: string
      file?: string
      upload?: string
      dossier?: string
      library?: string
    }) => {
      if (impromptuUploadUploadQueue.length > 0) {
        return overrides?.uploading ?? 'Uploading...'
      }
      if (
        selectedFiles.length > 0 &&
        uploadedFiles.length === 0 &&
        !onlyDossierFilesSelected
      ) {
        return `${selectedFiles.length} ${plural(overrides?.file ?? 'file', selectedFiles.length + uploadedFiles.length)}`
      } else if (uploadedFiles.length > 0 && selectedFiles.length === 0)
        return `${uploadedFiles.length} ${plural(overrides?.upload ?? 'upload', selectedFiles.length + uploadedFiles.length)}`
      else if (dossierDetail) {
        return overrides?.dossier ?? 'Dossier files'
      } else {
        return overrides?.library ?? 'Library'
      }
    },
    [
      selectedFiles,
      onlyDossierFilesSelected,
      dossierDetail,
      uploadedFiles,
      impromptuUploadUploadQueue,
    ]
  )

  return (
    <>
      <div className="flex gap-6 items-center">
        <div className="flex flex-wrap gap-3">
          <StateChip
            label={!isFocusedAnalysisSelected ? 'Library' : libraryLabel()}
            active={isInternalSearchSelected || isFocusedAnalysisSelected}
            leftIcon={<LibraryBig className="size-5 shrink-0 stroke-[1.5px]" />}
            rightIcon={
              isUploadSelected && (
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleUploadConnector()
                  }}
                >
                  <X className="size-6 shrink-0 stroke-[1.5px]" />
                </button>
              )
            }
            onClick={() => {
              if (isUploadSelected) {
                toggleUploadConnector()
              } else {
                toggleLibraryConnector(
                  isFocusedAnalysisSelected ? false : !isInternalSearchSelected
                )
              }
            }}
            dropdownContent={
              !isUploadSelected &&
              !impromptuUploadUploadQueue.length && (
                <>
                  <OptionDropdownMenuItem
                    selected={
                      isInternalSearchSelected ||
                      (sourceType === 'dossier' &&
                        onlyDossierFilesSelected &&
                        !settings.assistant.sources[sourceType].focusedAnalysis)
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleLibraryConnector(!isInternalSearchSelected)
                    }}
                  >
                    All Library files
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selected={
                      selectedFiles.length > 0 &&
                      settings.assistant.sources[sourceType].focusedAnalysis
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      setShownDocumentSelectorSource(
                        DocumentSelectionSource.LIBRARY
                      )
                    }}
                  >
                    <div className="flex gap-2 w-full">
                      {libraryLabel({
                        library: 'Selected files',
                      })}
                      <FileSearch className="size-6 shrink-0 stroke-[1.5px] ml-auto" />
                    </div>
                  </OptionDropdownMenuItem>
                </>
              )
            }
          />

          <StateChip
            label="Web"
            active={isWebSelected || isExcludedWebSelected}
            leftIcon={<Globe className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={() => {
              toggleWebConnector(isExcludedWebSelected ? false : !isWebSelected)
            }}
            dropdownContent={
              <div className="w-[16.715rem] flex flex-col gap-2">
                <OptionDropdownMenuItem
                  selected={isWebSelected && !isExcludedWebSelected}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (isWebSelected && isExcludedWebSelected) {
                      // if using exclude, just turn that off since webSearch is already on
                      toggleExcludedWebConnector(!isExcludedWebSelected)
                    } else {
                      toggleWebConnector(!isWebSelected)
                    }
                  }}
                >
                  All websites
                </OptionDropdownMenuItem>

                <OptionDropdownMenuItem
                  selected={isWebSelected && isExcludedWebSelected}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDomainSelectorDialogOpen(true)
                  }}
                >
                  <div className="flex gap-2 w-full">
                    {excludedDomains.length === 0
                      ? 'No excluded domains'
                      : `${excludedDomains.length} excluded ${plural('domain', excludedDomains.length)}`}
                    <Ban className="size-6 shrink-0 stroke-[1.5px] ml-auto" />
                  </div>
                </OptionDropdownMenuItem>
              </div>
            }
          />

          {((personalIntegrations?.length || 0) > 0 ||
            forceShowIntegrationsConnectors) && (
            <StateChip
              label={
                isCommunicationSelected && !allCommunicationSelected
                  ? '1 source'
                  : 'Communications'
              }
              active={isCommunicationSelected}
              leftIcon={
                <MessageCircleMore className="size-5 shrink-0 stroke-[1.5px]" />
              }
              onClick={toggleCommunicationConnector}
              openDropdown={showCommunicationDropdown}
              onDropdownOpen={(v) => setShowCommunicationDropdown(v)}
              dropdownContent={
                <>
                  <div
                    className="relative"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col gap-0 py-1">
                      {personalIntegrations?.map((integration, index) => {
                        return (
                          <OptionDropdownMenuItem
                            selectable={false}
                            key={`integration-dropdown-item-${index}`}
                            className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                          >
                            <div className="flex gap-2 py-2 items-start px-2 min-w-[17.5rem]">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                  integration.integration_code_name ===
                                  IntegrationCode.TEAMS
                                    ? settings.assistant.sources[sourceType]
                                        .teamsSearch
                                    : settings.assistant.sources[sourceType]
                                        .outlookSearch
                                }
                                onCheckedChange={() => {
                                  if (
                                    integration.integration_code_name ===
                                    IntegrationCode.TEAMS
                                  ) {
                                    updateSettings({
                                      settings: {
                                        ...settings,
                                        assistant: {
                                          ...settings.assistant,
                                          sources: {
                                            ...settings.assistant.sources,
                                            [sourceType]: {
                                              ...settings.assistant.sources[
                                                sourceType
                                              ],
                                              teamsSearch:
                                                !settings.assistant.sources[
                                                  sourceType
                                                ].teamsSearch,
                                            },
                                          },
                                        },
                                      },
                                    })
                                  } else if (
                                    integration.integration_code_name ===
                                    IntegrationCode.OUTLOOK
                                  ) {
                                    updateSettings({
                                      settings: {
                                        ...settings,
                                        assistant: {
                                          ...settings.assistant,
                                          sources: {
                                            ...settings.assistant.sources,
                                            [sourceType]: {
                                              ...settings.assistant.sources[
                                                sourceType
                                              ],
                                              outlookSearch:
                                                !settings.assistant.sources[
                                                  sourceType
                                                ].outlookSearch,
                                            },
                                          },
                                        },
                                      },
                                    })
                                  }
                                }}
                              />

                              <div className="flex flex-col gap-1 items-center">
                                <div className="flex gap-2 items-center w-full">
                                  <div className="flex items-center justify-center size-6 shrink-0 border-[1.5px] border-system-border-light rounded-sm">
                                    {getIntegrationIcon(
                                      integration.integration_code_name,
                                      true,
                                      'w-4 shrink-0'
                                    )}
                                  </div>

                                  <TypographyBody>
                                    {getIntegrationName(
                                      integration.integration_code_name
                                    )}
                                  </TypographyBody>
                                </div>

                                <TypographyLabel className="text-system-body">
                                  {getIntegrationDescriptionText(integration)}
                                </TypographyLabel>
                              </div>
                            </div>
                          </OptionDropdownMenuItem>
                        )
                      })}
                    </div>
                    <div className="absolute right-0 top-1">
                      <Button
                        variant="tertiary"
                        onClick={() => setShowCommunicationDropdown(false)}
                      >
                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
          )}

          <StateChip
            label={getDatabaseDropdownText(sources)}
            active={isDatabaseSelected || selectedDatabaseFiles.length > 0}
            leftIcon={<Database className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={toggleDatabaseConnector}
            openDropdown={showDatabaseDropdown}
            onDropdownOpen={(v) => setShowDatabaseDropdown(v)}
            dropdownContent={
              <>
                <div className="flex flex-col gap-0 px-2">
                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem] w-full">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isFinancialDataSelected}
                        onCheckedChange={toggleFinancialDataConnector}
                      />

                      <div
                        className="min-w-6"
                        data-tooltip-id="financial-icon-tooltip"
                        data-tooltip-place="top"
                      >
                        {getDatabaseIcon(
                          DatabaseConnector.FINANCIAL_DATA,
                          '!size-6 shrink-0 rounded-sm'
                        )}
                      </div>
                      <TypographyBody className="w-full">
                        Financial data
                      </TypographyBody>

                      <Button
                        variant="tertiary"
                        data-tooltip-id="financial-data-tooltip"
                        data-tooltip-place="right"
                        className="min-w-[1.75rem]"
                      >
                        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                      </Button>

                      <div
                        className={`${secondaryStyle} flex items-center justify-center rounded-lg border border-system-border-regular !p-1 ml-auto cursor-pointer opacity-0`}
                      >
                        <FileSearch className="size-[0.875rem] shrink-0 stroke-interactive" />
                      </div>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem] w-full">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isCompaniesHouseSelected}
                        onCheckedChange={toggleCompaniesHouseConnector}
                      />

                      <div
                        className="min-w-6"
                        data-tooltip-id="companies-house-icon-tooltip"
                        data-tooltip-place="top"
                      >
                        {getDatabaseIcon(
                          DatabaseConnector.COMPANIES_HOUSE,
                          '!size-6 shrink-0 rounded-sm'
                        )}
                      </div>

                      <TypographyBody className="w-full">
                        {companiesHouseFiles.length > 0
                          ? `${companiesHouseFiles.length} ${plural('file', companiesHouseFiles.length)}`
                          : `Companies House UK`}
                      </TypographyBody>

                      {companiesHouseFiles.length === 0 && (
                        <Button
                          variant="tertiary"
                          data-tooltip-id="companies-house-tooltip"
                          data-tooltip-place="right"
                          className="min-w-[1.75rem]"
                        >
                          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                        </Button>
                      )}

                      <div className="flex gap-2 items-center ml-auto">
                        {companiesHouseFiles.length > 0 && (
                          <Button
                            variant="tertiary"
                            className="ml-auto"
                            onClick={(e) => {
                              e.stopPropagation()
                              updateSettings({
                                settings: {
                                  ...settings,
                                  assistant: {
                                    ...settings.assistant,
                                    sources: {
                                      ...settings.assistant.sources,
                                      [sourceType]: {
                                        ...settings.assistant.sources[
                                          sourceType
                                        ],
                                        companiesHouseFiles: [
                                          ...filingsFiles,
                                          ...transcriptsFiles,
                                        ],
                                        focusedAnalysis:
                                          isFileSelectedForAnalysis ||
                                          [...filingsFiles, ...transcriptsFiles]
                                            .length > 0,
                                      },
                                    },
                                  },
                                },
                              })
                            }}
                          >
                            <X className="size-6 shrink-0 stroke-interactive" />
                          </Button>
                        )}

                        <div
                          className={`${secondaryStyle} flex items-center justify-center rounded-lg border ${companiesHouseFiles.length > 0 ? '!border-misc-blue-graphic !bg-[#ECF6FD]' : 'border-system-border-regular'} !p-1 cursor-pointer`}
                          onClick={(e) => {
                            e.stopPropagation()
                            setShownDocumentSelectorSource(
                              DocumentSelectionSource.COMPANIES_HOUSE
                            )
                          }}
                        >
                          <FileSearch
                            className={`size-[0.875rem] shrink-0 stroke-interactive ${companiesHouseFiles.length > 0 ? '!stroke-misc-blue-graphic' : ''}`}
                          />
                        </div>
                      </div>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem] w-full">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isFilingsSelected}
                        onCheckedChange={toggleFilingsConnector}
                      />

                      <div
                        className="min-w-6"
                        data-tooltip-id="filings-icon-tooltip"
                        data-tooltip-place="top"
                      >
                        {getDatabaseIcon(
                          DatabaseConnector.FILINGS,
                          '!size-6 shrink-0 rounded-sm'
                        )}
                      </div>
                      <TypographyBody className="w-full">
                        {filingsFiles.length > 0
                          ? `${filingsFiles.length} ${plural('file', filingsFiles.length)}`
                          : `Global companies filings`}
                      </TypographyBody>

                      {filingsFiles.length === 0 && (
                        <Button
                          variant="tertiary"
                          data-tooltip-id="filings-data-tooltip"
                          data-tooltip-place="right"
                          className="min-w-[1.75rem]"
                        >
                          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                        </Button>
                      )}

                      <div className="flex gap-2 items-center ml-auto">
                        {filingsFiles.length > 0 && (
                          <Button
                            variant="tertiary"
                            className="ml-auto"
                            onClick={(e) => {
                              e.stopPropagation()
                              updateSettings({
                                settings: {
                                  ...settings,
                                  assistant: {
                                    ...settings.assistant,
                                    sources: {
                                      ...settings.assistant.sources,
                                      [sourceType]: {
                                        ...settings.assistant.sources[
                                          sourceType
                                        ],
                                        companiesHouseFiles: [
                                          ...companiesHouseFiles,
                                          ...transcriptsFiles,
                                        ],
                                        focusedAnalysis:
                                          isFileSelectedForAnalysis ||
                                          [
                                            ...companiesHouseFiles,
                                            ...transcriptsFiles,
                                          ].length > 0,
                                      },
                                    },
                                  },
                                },
                              })
                            }}
                          >
                            <X className="size-6 shrink-0 stroke-interactive" />
                          </Button>
                        )}

                        <div
                          className={`${secondaryStyle} flex items-center justify-center rounded-lg border ${filingsFiles.length > 0 ? '!border-misc-blue-graphic !bg-[#ECF6FD]' : 'border-system-border-regular'} !p-1 cursor-pointer`}
                          onClick={(e) => {
                            e.stopPropagation()
                            setShownDocumentSelectorSource(
                              DocumentSelectionSource.FILINGS
                            )
                          }}
                        >
                          <FileSearch
                            className={`size-[0.875rem] shrink-0 stroke-interactive ${filingsFiles.length > 0 ? '!stroke-misc-blue-graphic' : ''}`}
                          />
                        </div>
                      </div>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem] w-full">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isTranscriptsSelected}
                        onCheckedChange={toggleTranscriptsConnector}
                      />

                      <div
                        className="min-w-6"
                        data-tooltip-id="transcripts-icon-tooltip"
                        data-tooltip-place="top"
                      >
                        {getDatabaseIcon(
                          DatabaseConnector.TRANSCRIPTS,
                          '!size-6 shrink-0 rounded-sm'
                        )}
                      </div>
                      <TypographyBody className="w-full">
                        {transcriptsFiles.length > 0
                          ? `${transcriptsFiles.length} ${plural('file', transcriptsFiles.length)}`
                          : `Earning call transcripts`}
                      </TypographyBody>

                      {transcriptsFiles.length === 0 && (
                        <Button
                          variant="tertiary"
                          data-tooltip-id="transcripts-data-tooltip"
                          data-tooltip-place="right"
                          className="min-w-[1.75rem]"
                        >
                          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                        </Button>
                      )}

                      <div className="flex gap-2 items-center ml-auto">
                        {transcriptsFiles.length > 0 && (
                          <Button
                            variant="tertiary"
                            className="ml-auto"
                            onClick={(e) => {
                              e.stopPropagation()
                              updateSettings({
                                settings: {
                                  ...settings,
                                  assistant: {
                                    ...settings.assistant,
                                    sources: {
                                      ...settings.assistant.sources,
                                      [sourceType]: {
                                        ...settings.assistant.sources[
                                          sourceType
                                        ],
                                        companiesHouseFiles: [
                                          ...companiesHouseFiles,
                                          ...filingsFiles,
                                        ],
                                        focusedAnalysis:
                                          isFileSelectedForAnalysis ||
                                          [
                                            ...companiesHouseFiles,
                                            ...filingsFiles,
                                          ].length > 0,
                                      },
                                    },
                                  },
                                },
                              })
                            }}
                          >
                            <X className="size-6 shrink-0 stroke-interactive" />
                          </Button>
                        )}

                        <div
                          className={`${secondaryStyle} flex items-center justify-center rounded-lg border ${transcriptsFiles.length > 0 ? '!border-misc-blue-graphic !bg-[#ECF6FD]' : 'border-system-border-regular'} !p-1 cursor-pointer`}
                          onClick={(e) => {
                            e.stopPropagation()
                            setShownDocumentSelectorSource(
                              DocumentSelectionSource.TRANSCRIPTS
                            )
                          }}
                        >
                          <FileSearch
                            className={`size-[0.875rem] shrink-0 stroke-interactive ${transcriptsFiles.length > 0 ? 'stroke-misc-blue-graphic' : ''}`}
                          />
                        </div>
                      </div>
                    </div>
                  </OptionDropdownMenuItem>

                  <CustomTooltip
                    id="financial-icon-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Financial data`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="companies-house-icon-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Companies House UK`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="filings-icon-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Global companies filings`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="transcripts-icon-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Earning call transcripts`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="companies-house-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`UK's official business registry containing mandatory\ncompany filings, accounts and ownership details`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="financial-data-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Financialmodelingprep.com provides financial data on\npublic companies`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="filings-data-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      Collection of filings from public companies worldwide.
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="transcripts-data-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      Quarterly earnings presentations from public companies.
                    </TypographyLabel>
                  </CustomTooltip>
                </div>
              </>
            }
          />
        </div>
      </div>

      <DocumentSelectorDialog
        sourceType={sourceType}
        open={Boolean(shownDocumentSelectorSource)}
        dossierDetail={dossierDetail}
        setOpen={(v) => {
          if (!v) {
            setShownDocumentSelectorSource(null)
          }
        }}
        type={DocumentSelectorDialogType.SETTINGS}
        initialDocumentSource={shownDocumentSelectorSource || undefined}
      />
      <DomainSelectorDialog
        open={isDomainSelectorDialogOpen}
        setOpen={(value) => {
          setIsDomainSelectorDialogOpen(value)
        }}
        sourceType={sourceType}
      />

      <AskUploadFileRemoveDialog
        open={isUploadremoveDialogOpen}
        onConfirm={() => {
          toggleUploadConnector()
          setIsUploadRemoveDialogOpen(false)
        }}
        onCancel={() => {
          setIsUploadRemoveDialogOpen(false)
        }}
      />
    </>
  )
}
