import { EllipsisVertical, Pin } from 'lucide-react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Playbook } from '@/types/types'
import { Button, secondaryStyle } from '../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'
import { getPlaybookCardIcon } from '@/utils/components'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { deletePlaybook, updatePlaybook } from './playbookThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { playbookActions } from './playbookSlice'

export const PlaybookCard = ({
  playbook,
  onClick,
  onPreview,
  onEdit,
}: {
  playbook: Playbook
  onClick: () => void
  onPreview: () => void
  onEdit?: () => void
}) => {
  const firstPlaybookItem = playbook.items.at(0)
  const userCreated = playbook.items.every((v) => v.kind === 'user')

  const dispatch = useDispatch<AppDispatch>()

  const deletePlaybookHandler = async () => {
    if (!firstPlaybookItem) return
    const result = await dispatch(deletePlaybook(firstPlaybookItem.id))

    if (isFulfilled(deletePlaybook)(result)) {
      dispatch(playbookActions.removePlaybook(playbook))
    }
  }

  const togglePinnedPlaybook = async () => {
    if (!firstPlaybookItem) return
    const result = await dispatch(
      updatePlaybook({
        ...firstPlaybookItem,
        pinned: !firstPlaybookItem.pinned,
      })
    )

    if (isFulfilled(updatePlaybook)(result)) {
      dispatch(playbookActions.replacePlaybook(playbook))
    }
  }

  return (
    <div
      onClick={onClick}
      className={`${secondaryStyle} flex gap-3 items-center !p-3 bg-system-secondary border !border-system-border-light rounded-lg cursor-pointer w-full`}
    >
      <div className="h-fit relative">
        {getPlaybookCardIcon(firstPlaybookItem?.category || '')}

        {firstPlaybookItem?.pinned && (
          <Pin className="absolute -top-1.5 -left-1.5 size-4 shrink-0 stroke-interactive -rotate-[30deg] stroke-system-placeholder" />
        )}
      </div>

      <div className="flex gap-2 items-center w-full h-fit">
        <TypographyBody
          isStrong
          className="text-system-primary w-full line-clamp-2"
        >
          {playbook.title}
        </TypographyBody>

        <Button
          variant="tertiary"
          data-tooltip-id={`playbook-card-${firstPlaybookItem?.id || ''}-tooltip`}
          data-tooltip-place="top"
          className="size-4"
        >
          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
        </Button>

        <CustomTooltip
          id={`playbook-card-${firstPlaybookItem?.id || ''}-tooltip`}
          className="!py-1 !px-3 !rounded-sm"
          largeArrow={false}
        >
          <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
            {firstPlaybookItem?.tooltip || ''}
          </TypographyLabel>
        </CustomTooltip>
      </div>
      <div className="shrink-0 h-[1.75rem]">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="tertiary" className="h-fit">
              <EllipsisVertical className="size-6 shrink-0 stroke-interactive" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            className="min-w-[11.875rem]"
            onClick={(e) => e.stopPropagation()}
          >
            <DropdownMenuItem
              variant="tertiary"
              onClick={(e) => {
                e.stopPropagation()
                onClick()
              }}
            >
              Use in Ask
            </DropdownMenuItem>

            <DropdownMenuItem
              variant="tertiary"
              onClick={(e) => {
                e.stopPropagation()
                onPreview()
              }}
            >
              Preview
            </DropdownMenuItem>

            {userCreated && (
              <>
                <DropdownMenuItem
                  variant="tertiary"
                  onClick={(e) => {
                    e.stopPropagation()
                    onEdit?.()
                  }}
                >
                  Edit
                </DropdownMenuItem>

                <DropdownMenuItem
                  variant="tertiary"
                  onClick={(e) => {
                    e.stopPropagation()
                    togglePinnedPlaybook()
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <Pin className="size-6 shrink-0 stroke-interactive -rotate-[30deg]" />
                    {firstPlaybookItem?.pinned
                      ? 'Unpin from Ask Desia'
                      : ' Pin to Ask Desia'}
                  </div>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem
                  variant="tertiary-destructive"
                  onClick={(e) => {
                    e.stopPropagation()
                    deletePlaybookHandler()
                  }}
                >
                  Delete
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
