import { ReactNode, createContext, useEffect, useState } from 'react'
import { WEB_SERVER_ENDPOINT } from '../constants'
import { ResAppUser } from '../types/types'
import { UserSettings } from '../types/types'
import {
  getUserSettings,
  isUploadFileEnabled,
  saveUserSettings,
} from '../utils/utils'
import { handleError } from '@/utils/handleError'

type UserState = {
  user: ResAppUser | null
  status: 'loading' | 'error' | 'success'
}

type Handlers = {
  updateSettings: (s: UserSettings) => void
  refetchUser: () => void
}
type UserContext = UserState & UserSettings & Handlers

const initialUserContext: UserContext = {
  user: null,
  status: 'loading',
  settings: {
    assistant: {
      sources: {
        ask: {
          internalSearch: true,
          webSearch: true,
          excludedWebSearch: false,
          teamsSearch: false,
          outlookSearch: false,
          focusedAnalysis: false,
          companiesHouseSearch: false,
          financialDataSearch: false,
          filingsSearch: false,
          transcriptsSearch: false,
          files: [],
          uploadedFiles: [],
          companiesHouseFiles: [],
        },
        dossier: {
          internalSearch: true,
          webSearch: true,
          excludedWebSearch: false,
          teamsSearch: false,
          outlookSearch: false,
          focusedAnalysis: false,
          companiesHouseSearch: false,
          financialDataSearch: false,
          filingsSearch: false,
          transcriptsSearch: false,
          files: [],
          uploadedFiles: [],
          companiesHouseFiles: [],
        },
        report: {
          internalSearch: true,
          webSearch: true,
          excludedWebSearch: false,
          teamsSearch: false,
          outlookSearch: false,
          focusedAnalysis: false,
          companiesHouseSearch: false,
          financialDataSearch: false,
          filingsSearch: false,
          transcriptsSearch: false,
          files: [],
          uploadedFiles: [],
          companiesHouseFiles: [],
        },
      },
      parameters: {},
      mode: 'simple',
    },
  },
  updateSettings: () => {},
  refetchUser: () => {},
} satisfies UserContext

export const UserContext = createContext<UserContext>(initialUserContext)

function UserProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<UserState>({ user: null, status: 'loading' })
  const [settings, setSettings] = useState<UserSettings>({
    settings: {
      assistant: {
        sources: {
          ask: {
            internalSearch: true,
            webSearch: true,
            excludedWebSearch: false,
            teamsSearch: false,
            outlookSearch: false,
            focusedAnalysis: false,
            companiesHouseSearch: false,
            financialDataSearch: false,
            filingsSearch: false,
            transcriptsSearch: false,
            files: [],
            uploadedFiles: [],
            companiesHouseFiles: [],
          },
          dossier: {
            internalSearch: true,
            webSearch: true,
            excludedWebSearch: false,
            teamsSearch: false,
            outlookSearch: false,
            focusedAnalysis: false,
            companiesHouseSearch: false,
            financialDataSearch: false,
            filingsSearch: false,
            transcriptsSearch: false,
            files: [],
            uploadedFiles: [],
            companiesHouseFiles: [],
          },
          report: {
            internalSearch: true,
            webSearch: true,
            excludedWebSearch: false,
            teamsSearch: false,
            outlookSearch: false,
            focusedAnalysis: false,
            companiesHouseSearch: false,
            financialDataSearch: false,
            filingsSearch: false,
            transcriptsSearch: false,
            files: [],
            uploadedFiles: [],
            companiesHouseFiles: [],
          },
        },
        parameters: {},
        mode: 'simple',
      },
    },
  })

  function updateSettings(s: UserSettings) {
    setSettings(s)
    saveUserSettings(s)
  }

  function loadUserSettings() {
    const settings = getUserSettings()
    if (settings) {
      const sources = settings.settings.assistant.sources

      Object.keys(sources).forEach((sourceType) => {
        sources[sourceType] = {
          internalSearch: sources[sourceType].internalSearch || false,
          webSearch: sources[sourceType].webSearch || false,
          excludedWebSearch: sources[sourceType].excludedWebSearch || false,
          teamsSearch: sources[sourceType].teamsSearch || false,
          outlookSearch: sources[sourceType].outlookSearch || false,
          focusedAnalysis: sources[sourceType].focusedAnalysis || false,
          companiesHouseSearch:
            sources[sourceType].companiesHouseSearch || false,
          financialDataSearch: sources[sourceType].financialDataSearch || false,
          filingsSearch: sources[sourceType].filingsSearch || false,
          transcriptsSearch: sources[sourceType].transcriptsSearch || false,
          files: Array.isArray(sources[sourceType].files)
            ? sources[sourceType].files
            : [],
          companiesHouseFiles: Array.isArray(
            sources[sourceType].companiesHouseFiles
          )
            ? sources[sourceType].companiesHouseFiles
            : [],
          ...(isUploadFileEnabled(sourceType) && {
            uploadedFiles: Array.isArray(sources[sourceType].uploadedFiles)
              ? sources[sourceType].uploadedFiles
              : [],
          }),
        }
      })

      const updatedSettings = {
        settings: {
          ...settings.settings,
          assistant: {
            ...settings.assistant,
            sources: sources,
            mode: settings.assistant?.mode || 'simple',
          },
        },
      }

      setSettings(updatedSettings)
    }
  }

  async function refetchUser() {
    try {
      const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/user/profile`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      if (res.status !== 200) {
        throw new Error('We failed to fetch your profile')
      }
      const data: ResAppUser = await res.json()
      setUser((prev) => ({
        ...prev,
        user: data,
        status: 'success',
      }))
      loadUserSettings()
    } catch (e) {
      setUser((prev) => ({
        ...prev,
        user: null,
        status: 'error',
      }))
      handleError(e)
    }
  }

  useEffect(() => {
    refetchUser()
  }, [])

  return (
    <UserContext.Provider
      value={{
        user: user.user,
        status: user.status,
        settings: settings.settings,
        updateSettings,
        refetchUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserProvider }
